// Loading Spinner
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  display: inline-block;
  width: 40px;
  height: 40px;
  position: relative;
  z-index: z("content-overlay");
  border-radius: 50%;

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-top: 4px solid $brand-secondary;
    border-right: 4px solid transparent;
    animation: spin 0.6s linear infinite;

    top: 50%;
    left: 50%;
    margin: -20px 0 0 -20px;
    animation: spinner 0.6s linear infinite;
  }

  &--center {
    position: fixed;
    top: 50%;
    left: 50%;
  }
}

.spinner_salesbeat {
  display: inline-block;
  width: 140px;
  height: 140px;
  position: relative;
  z-index: z("content-overlay");
  border-radius: 50%;

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-top: 4px solid $brand-secondary;
    border-right: 4px solid transparent;
    animation: spin 0.6s linear infinite;

    top: 50%;
    left: 50%;
    margin: -140px 0 0 -140px;
    animation: spinner 0.6s linear infinite;
  }

  &--center {
    position: fixed;
    top: 50%;
    left: 50%;
  }
}

.spinner_common {
  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;

    &__message {
      padding-left: 1rem;
      text-transform: uppercase;
      font-size: 0.875rem;
      font-weight: 500;
      color: $brand-secondary;
    }
  }
}

.spinner_message {
  &::before {
    width: 60%;
    height: 60%;
    left: 100%;
    top:60%;
  }
  
}
