// Alert Component

.alert {
  border:1px solid $gray-lighter;
  padding:1rem;
  margin-bottom:1rem;

  > p {
    margin-bottom:1rem;
  }

  .icon {
    width:1.5em;
    height:1.5em;
    margin-right:.5em;
  }

  // Variations
  &--info {
    color:$state-info;
    background-color:lighten($state-info, 45%);
    border-color:lighten($state-info, 35%);

    .btn {
      background-color: lighten($state-info, 25%);
      color:#FFF;

      @include hover-focus {
	      background-color: lighten($state-info, 10%);
      }
    }
  }
  &--warning {
    color: darken($state-warning, 7%);
    border-color:lighten($state-warning, 35%);
    background-color:lighten($state-warning, 44%);

    .btn {
      background-color: desaturate(lighten($state-warning, 20%), 10%);
      color:#FFF;

      @include hover-focus {
	      background-color: desaturate(lighten($state-warning, 5%), 10%);
      }
    }
  }
  &--success {
    color: #FFF;
    border-color:lighten($state-success, 0%);
    background-color:lighten($state-success, 0%);

    .btn {
      background-color: desaturate($state-success, 10%);
      color:#FFF;

      @include hover-focus {
	      background-color: darken($state-success, 10%);
      }
    }
  }
  &--error {
    color: darken($state-error, 5%);
    border-color:lighten($state-error, 45%);
    background-color:desaturate(lighten($state-error, 45%), 50%);

    .btn {
      background-color: desaturate(lighten($state-error, 13%), 30%);
      color:#FFF;

      @include hover-focus {
	      background-color: darken($state-error, 5%);
      }
    }
  }
  &--upgrade {
    color:#FFF;
    background-color:#498CBD;
    border:0;
    padding:1.5rem;
    margin-bottom:$spacing-y;

    .btn {
      color:#FFF;
      border-color:#FFF;

      @include hover-focus {
        background-color:#FFF;
        color: #498CBD;
      }
    }

    @include mq(md){
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      > p {
        margin:0;
      }
    }
  }
}