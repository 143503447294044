// Employee List Component
.employees {
  min-height: 25em;

  &__filter {
    padding-top: 1.5rem;
    width: 100%;
    margin-right: 8px;
    display: inherit;
    margin-bottom: -1em;

    @include mq-down(md) {
      display: block;
      padding-top: 1rem;
    }

    &__search {
      margin-left: 0.5rem;
    }

    &__select {
      top: 0.85rem;

      @include mq-down(md) {
        top: 0em;
        padding-top: 0.5em;
        margin-left: 0.5em;
        padding-bottom: 1em;
      }
    }
  }

  &__blanktext {
    padding-left: 1rem;
  }

  &__list {
    padding-top: 2em;
    list-style: none;

    @include mq(md) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__item {
    margin-bottom: $spacing-y;

    &:last-child {
      margin: 0;
    }

    @include mq(md) {
      width: 20%;
      padding: 0 1rem 1rem;
      margin: 0;
    }
  }
}

.employee {
  text-align: center;
  font-size: 0.875rem;

  &__image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    object-position: 50% 50%;
    border-radius: 50%;
    margin-bottom: $spacing-y/2;

    .icon {
      width: 100px;
      height: 100px;
    }
  }

  &__name {
    font-weight: 500;
    font-size: 1em;
    margin-bottom: 0;
  }

  &__position {
    display: block;
    font-style: italic;
  }

  &__email {
    @include truncate;
    display: block;
  }

  &__social {
    font-size: 1.125rem;

    &__link {
      color: $gray-light;
      display: inline-block;
      padding: 0.5rem;
      transition: color 0.2s linear;

      &--linkedin {
        color: $linkedin-color;

        @include hover-focus {
          color: darken($linkedin-color, 10%);
        }
      }

      &--xing {
        color: $xing-color;

        @include hover-focus {
          color: darken($xing-color, 10%);
        }
      }

      &--twitter {
        color: $twitter-color;

        @include hover-focus {
          color: darken($twitter-color, 10%);
        }
      }

      &--export {
        color: $brand-blue;

        @include hover-focus {
          color: darken($brand-blue, 10%);
        }
      }

      &--zapier {
        border: none;
        background-color: transparent;
        color: $zapier-color;

        @include hover-focus {
          color: darken($zapier-color, 10%);
        }
      }

    }
  }
}

.employeeshidden {
  display: none !important;
}

.buttonshowemployees {
  margin: 10px;
}