//--------------------
// SCSS VARIABLES
//--------------------
//default bootstrap variables,
//override where necessary
@import "~bootstrap/scss/variables";
// Grid breakpoints
//
// Define the minimum and maximum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries. - (original xl was 1280px)
$grid-breakpoints: (
  md: 768px,
  lg: 1024px,
  xl: 1450px
);
// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1180px
);
// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-columns: 12;
$grid-gutter-width: 30px;
$grid-gutter-widths: (
  xs: $grid-gutter-width,
  sm: $grid-gutter-width,
  md: $grid-gutter-width,
  lg: $grid-gutter-width,
  xl: $grid-gutter-width
);
// Z-Index Stack
//
// Control the order of layers in the application
$z-layers: (
  "modal": 999,
  "topbar": 100,
  "navigation": 75,
  "search": 50,
  "content-overlay": 25
);
// Spacing
//
// Default Alignment and Whitespace variables
$spacing-x: $grid-gutter-width;
$spacing-y: 0.5rem;
// Colors
//
// Grayscale.
$gray-darkest: #2f2f2f;
$gray-dark: #4a4a4a;
$gray: lighten($gray-dark, 25%);
$gray-light: #9b9b9b;
$gray-lighter: #d8d8d8;
$gray-lightest: #f7f7f7;
$white: #ffffff;
// Brand Colors
$brand-blue: #005fa5;
$brand-blue-light: #498cbd;
$brand-blue-lightest: #bfd7e8;
$brand-coral: #ff6559;
$brand-cyan: #00c49f;
// State Colors
$state-success: $brand-blue;
$state-info: #498cbd;
$state-warning: #df8a13;
$state-error: #d0021b;
//Color Mappings
$brand-primary: $brand-coral;
$brand-secondary: $brand-blue;
// Links
// Buttons
// $no-button: lighten(#DD4B39, 10%);
$no-button: #dd4b39;
$yes-button: #00960a;
// Style anchor elements.
$link-color: $brand-primary;
$link-decoration: none;
$link-hover-color: $brand-primary;
$link-hover-decoration: underline;
// Social
$linkedin-color: #0077b5;
$xing-color: #007575;
$facebook-color: #3b5998;
$twitter-color: #55acee;
$youtube-color: #ff0000;
$newspaper-color: $gray-dark;
$zapier-color: #ff4a00;
// Body
//
// Settings for the `<body>` element.
$body-bg: $white;
$body-color: $gray-dark;
// Typography
//
// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif: Segoe UI, Tahoma, Roboto, Helvetica, Arial, sans-serif;
$font-family-serif: Georgia, "Times New Roman", Times, serif;
$font-family-monospace: Menlo, Monaco, Consolas, "Andale Mono", "Courier New",
  monospace;
$font-family-base: $font-family-sans-serif;
$font-size-root: 16px;
$font-size-base: 1rem;
$line-height: 1.5;
$leading: round(16 * $line-height);
$leading-rem: $leading / 16;
$font-size-h1: 1.625rem; //26px
$font-size-h2: 1.125rem; //18px
$font-size-h3: 1rem; //16px
// Animation
//
// Default easing curves, Transitions, etc
$animation-curve-fast-out-slow-in: cubic-bezier(0.4, 0, 0.2, 1);
$animation-curve-linear-out-slow-in: cubic-bezier(0, 0, 0.2, 1);
$animation-curve-fast-out-linear-in: cubic-bezier(0.4, 0, 1, 1);
$animation-curve-default: $animation-curve-fast-out-slow-in;
// Shadows
$box-shadow-default: 0 2px 4px rgba($gray-lighter, 0.8);
$box-shadow-elevated: 0 10px 45px -5px rgba($gray, 0.25);
// Components
$box-padding: 1.375rem;
$box-toggle-arrow-size: 60px;
$topbar-height: 65px;
$nav-width-md: 385px;
$nav-width-lg: 200px;
$nav-width-xl: 300px;
// EXTERNAL APPLICATIONS
// opportunity management
$opm-blue:rgb(69, 153, 255)
