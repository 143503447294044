// Table Component
// Common Styles
.table {
  width: 100%;

  &__header,
  &__cell {
    padding: 0.25rem 0.5rem;

    &:first-child {
      padding-left: 0;
    }
  }

  &__header {
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__cell {
    @include truncate;
    position: relative;
    vertical-align: middle;
    font-size: 0.875rem;

    &__btn {
      padding: 0;
      font-size: 1.5rem;
      transition: none;
    }

    &--action {
      padding-right: 0;
      text-align: center !important;
    }

    &--editTerm {
      text-align: right;
    }
  }

  &__foot td {
    padding-top: 0.5rem;
    border-top: 1px solid $gray-lighter;
  }
}

// Add/Remove Companies Table
.table--companies {
  table-layout: fixed; //clickable rows

  .table__row:hover {
    cursor: pointer;

    td {
      color: $brand-primary;
      background-color: darken($gray-lightest, 2%);
    }
  }

  .table__header,
  .table__cell {
    &--country {
      width: 85px;
    }
    &--status {
      width: 85px;
      .dot {
        height: 10px;
        width: 10px;

        border-radius: 50%;
        display: inline-block;
        &--red {
          background-color: $no-button;
        }
        &--green {
          background-color: $yes-button;
        }
        &--gray {
          background-color: $gray-light;
        }
        &--orange {
          background-color: $state-warning;
        }
      }
    }

    &--number {
      width: 240px;
    }

    &--action {
      width: 40px;
    }
  }

  @include mq-down(md) {
    .table__header,
    .table__cell {
      &--country {
        width: 65px;
        display: none;
      }
      &--status {
        width: 50px;
      }
    }

    .table__cell--country {
      text-align: center;
    }

    .table__header--number,
    .table__cell--number {
      display: none;
    }
  }
}

// Add/Remove Campaigns Table
.table--campaigns {
  table-layout: fixed; //clickable rows

  .table__row:hover {
    cursor: pointer;

    td {
      color: $brand-primary;
      background-color: darken($gray-lightest, 2%);
    }
  }

  .table__header {
    &--campaignName {
      width: 20%;
      font-size: 1rem;
      color: $gray-light;
      border-bottom: 2px solid rgb(216, 216, 216);
      text-transform: initial;
    }

    &--searchTerm {
      width: 50%;
      font-size: 1rem;
      color: $gray-light;
      border-bottom: 2px solid rgb(216, 216, 216);
      text-transform: initial;
    }

    &--excludeTerm {
      width: 20%;
      font-size: 1rem;
      color: $gray-light;
      border-bottom: 2px solid rgb(216, 216, 216);
      text-transform: initial;
    }

    &--editTerm {
      width: 10%;
      text-align: right;
      font-size: 1rem;
      color: $gray-light;
      border-bottom: 2px solid rgb(216, 216, 216);
      text-transform: initial;
    }
  }

  .table__cell {
    &--campaignName {
      width: 20%;
      font-size: 0.875rem;
    }

    &--searchTerm {
      width: 50%;
      font-size: 0.875rem;
    }

    &--excludeTerm {
      width: 20%;
      font-size: 0.875rem;
    }

    &--editTerm {
      width: 10%;
      text-align: right;
      text-transform: initial;
      font-size: 0.875rem;
    }
  }

  @include mq-down(md) {
    .table__header,
    .table__cell {
      &--campaignName {
        width: 60%;
        text-transform: initial;
      }

      &--searchTerm {
        display: none;
        text-transform: initial;
      }

      &--excludeTerm {
        display: none;
        text-transform: initial;
      }

      &--editTerm {
        width: 30%;
        text-transform: initial;
      }
    }

    .table__cell--campaignName {
      text-align: left;
    }

    .table__header--searchTerm,
    .table__cell--searchTerm {
      display: none;
    }
  }
}

// Add/Remove Campaigns Table
.table--leads {
  table-layout: fixed; //clickable rows

  .table__row:hover {
    cursor: pointer;

    td {
      color: $brand-primary;
      background-color: darken($gray-lightest, 2%);
    }
  }

  .table__header {
    &--leadName {
      width: 20%;
      font-size: 1rem;
      color: $gray-light;
      border-bottom: 2px solid rgb(216, 216, 216);
      text-transform: initial;
    }

    &--criteria {
      width: 62%;
      font-size: 1rem;
      color: $gray-light;
      border-bottom: 2px solid rgb(216, 216, 216);
      text-transform: initial;
    }

    &--created {
      width: 8%;
      font-size: 1rem;
      color: $gray-light;
      border-bottom: 2px solid rgb(216, 216, 216);
      text-transform: initial;
    }

    &--editTerm {
      width: 10%;
      text-align: right;
      font-size: 1rem;
      color: $gray-light;
      border-bottom: 2px solid rgb(216, 216, 216);
      text-transform: initial;
    }
  }

  .table__cell {
    &--leadName {
      width: 20%;
      font-size: 0.875rem;
      .icon {
        margin-right: 0.5em;
        margin-bottom: -0.1em;
      }
    }

    &--criteria {
      width: 62%;
      font-size: 0.875rem;
    }

    &--created {
      width: 8%;
      font-size: 0.875rem;
    }

    &--editTerm {
      width: 10%;
      text-align: right;
      text-transform: initial;
      font-size: 0.875rem;
    }
    &--headLine {
      font-style: italic;
    }
  }

  @include mq-down(md) {
    .table__header,
    .table__cell {
      &--leadName {
        width: 60%;
        text-transform: initial;
      }

      &--criteria {
        display: none;
        text-transform: initial;
      }

      &--created {
        display: none;
        text-transform: initial;
      }

      &--editTerm {
        width: 30%;
        text-transform: initial;
      }
    }

    .table__cell--leadName {
      text-align: left;
    }

    .table__header--criteria,
    .table__cell--created {
      display: none;
    }
  }
}

// User Management Table
.table--users {
  table-layout: fixed;

  .table__header {
    &--firstname {
      width: 25%;
    }

    &--lastname {
      width: 30%;
    }

    &--email {
      width: 35%;
    }

    // &--companies {
    //   width: 15%;
    // }

    &--edit {
      width: 10%;
    }
  }

  // Stack TDs on mobile
  @include mq-down(md) {
    .table__head,
    .table__foot {
      display: none;
    }

    .table__row,
    .table__cell {
      display: block;
    }

    .table__body .table__row:not(:first-child) {
      padding-top: 1rem;
      margin-top: 1rem;
      border-top: 1px solid $gray-lighter;
    }

    .table__cell {
      padding: 0.5rem 0.5rem 0.5rem 33.33%;
      text-align: left;
    }

    .table__cell[data-column]::before {
      @include truncate;
      display: block;
      content: attr(data-column);
      width: 33.33%;
      font-size: 0.75rem;
      font-weight: 500;
      text-transform: uppercase;
      padding-right: 1em;
      position: absolute;
      top: 9px;
      left: 0;
    }
  }
}


@media screen and (max-width: 1200px) {
  .fit-content {
    width: fit-content;
  }

  .processes-table {
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
  }
}
