.svg-map {
  width: 70%;
  height: auto;
  stroke: #666;
  stroke-width: 1.8;
  stroke-linecap: round;
  stroke-linejoin: round;

  &__location {
    fill: whitesmoke;
    cursor: zoom-out;

    &:focus,
    &:hover {
      fill: lightgrey;
      outline: 0;
    }
  }
}

.svg-map-color {
  &__small {
    fill: $brand-blue !important;
    fill-opacity: 0.3;
  }

  &__medium {
    fill: $brand-blue !important;
    fill-opacity: 0.6;
  }

  &__high {
    fill: $brand-blue !important;
    fill-opacity: 1;
  }
}

.svg-map-color-potential-customers {
  &__small {
    fill: $brand-coral !important;
    fill-opacity: 0.3;
  }

  &__medium {
    fill: $brand-coral !important;
    fill-opacity: 0.6;
  }

  &__high {
    fill: $brand-coral !important;
    fill-opacity: 1;
  }
}

.svg-map-world {
  width: 100%;
  height: auto;
  stroke: #666;
  stroke-width: 0.3;
  stroke-linecap: round;
  stroke-linejoin: round;

  &__location {
    fill: white;
    cursor: zoom-in;
  }

  &__inaccessible_location {
    fill: whitesmoke;
    cursor: pointer;

    &:focus,
    &:hover {
      fill: lightgrey;
      outline: 0;
    }
  }
}

.svg-map-container {
  display: flex;
  align-items: center;
  justify-content: center;
}