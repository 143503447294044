.statistics-qualifier {
  text-align: center;
  .icon {
    width: 3.5em;
    height: 3.5em;
    stroke: $brand-blue;
    margin: 10px;
}

@include mq(xl) {
  &__portfolio {
    margin-top: 35px;
  }
}

  &--span {
    font-size: 30px;

    &--title {
      margin-bottom: 2px;
      font-size: 16px;
    }
  }
}
.qualifier-btn {
  color: $gray-light;
  padding: 0;
  &+& {
    margin-left: 0.5em;
  }

  &__pause {

    .icon {
      width: 1em;
      height: 1em;
    }
    &:hover {
      color: #FFBB28;
    }

    &--active {
      padding-left: 6px;
      color: #FFBB28;
    }
    }

  &__play {
    &--active {
      .icon {
        width: 1.80em;
        height: 1.80em;
        margin-right: -4px;
        &:hover {
          border: $gray-dark;
        }
      }
      color: $brand-cyan;
      }
      
  }

  &__trash {

    &--active {
      .icon {
        width: 1.45em;
        height: 1.45em;
      
        &:hover {
          stroke: $gray-dark;
        }
      }

      stroke: black;
    }
  }
&__edit2-template {

  &--active {
    .icon {
      width: 0.85em;
      height: 0.85em;

      &:hover {
        stroke: $gray-dark;
      }
    }

    stroke: black;
  }
}

&__trash-template {

  &--active {
    .icon {
      width: 0.85em;
      height: 0.85em;

      &:hover {
        stroke: $gray-dark;
      }
    }

    stroke: black;
  }
}

&__eye-template {

  &--active {
    .icon {
      width: 0.85em;
      height: 0.85em;

      &:hover {
        stroke: $gray-dark;
      }
    }

    stroke: black;
  }
}

}
.qualifier-template {
  &__padding {
    @include mq-down(lg) {
      padding-top: 1em;
    }
  }
  &__error {
    textarea {
      border-color: red;
    }

    input {
      border-color: red;
    }

    div {
      border-color: red;
    }
  }
}
.qualifier-message-category {
  position: absolute;
  display: none;
  z-index: z(content-overlay);
  width: auto;
  left: 64px;
  min-width: 10em;
  height: auto;
  background-color: #FFF;
  border: 1px solid $gray-lighter;

  &__head {
    padding: 0.25em 0.5em;
    font-size: 1em;
    font-weight: 500;
    border-bottom: 1px solid $gray-lighter;
  }


  &__element {
    font-size: 0.875em;
    padding: 0.25em 0.5em;
    cursor: pointer;

    &:hover {
      background-color: $gray-lighter;
    }

    &__selected {
      font-size: 0.875em;
      padding: 0.25em 0.5em;
      color: $gray-lighter;
    }
  }
}

.duplicate-tooltip-deactive {

  .icon {
    stroke: lightgrey !important;
  }
  
}

:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animate__animated.animate__infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animate__animated.animate__repeat-1 {
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: var(--animate-repeat);
  animation-iteration-count: var(--animate-repeat);
}

.animate__animated.animate__repeat-2 {
  -webkit-animation-iteration-count: calc(1 * 2);
  animation-iteration-count: calc(1 * 2);
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 2);
  animation-iteration-count: calc(var(--animate-repeat) * 2);
}

.animate__animated.animate__repeat-3 {
  -webkit-animation-iteration-count: calc(1 * 3);
  animation-iteration-count: calc(1 * 3);
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 3);
  animation-iteration-count: calc(var(--animate-repeat) * 3);
}

.animate__animated.animate__delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-delay: var(--animate-delay);
  animation-delay: var(--animate-delay);
}

.animate__animated.animate__delay-2s {
  -webkit-animation-delay: calc(1s * 2);
  animation-delay: calc(1s * 2);
  -webkit-animation-delay: calc(var(--animate-delay) * 2);
  animation-delay: calc(var(--animate-delay) * 2);
}

.animate__animated.animate__delay-3s {
  -webkit-animation-delay: calc(1s * 3);
  animation-delay: calc(1s * 3);
  -webkit-animation-delay: calc(var(--animate-delay) * 3);
  animation-delay: calc(var(--animate-delay) * 3);
}

.animate__animated.animate__delay-4s {
  -webkit-animation-delay: calc(1s * 4);
  animation-delay: calc(1s * 4);
  -webkit-animation-delay: calc(var(--animate-delay) * 4);
  animation-delay: calc(var(--animate-delay) * 4);
}

.animate__animated.animate__delay-5s {
  -webkit-animation-delay: calc(1s * 5);
  animation-delay: calc(1s * 5);
  -webkit-animation-delay: calc(var(--animate-delay) * 5);
  animation-delay: calc(var(--animate-delay) * 5);
}

.animate__animated.animate__faster {
  -webkit-animation-duration: calc(1s / 2);
  animation-duration: calc(1s / 2);
  -webkit-animation-duration: calc(var(--animate-duration) / 2);
  animation-duration: calc(var(--animate-duration) / 2);
}

.animate__animated.animate__fast {
  -webkit-animation-duration: calc(1s * 0.8);
  animation-duration: calc(1s * 0.8);
  -webkit-animation-duration: calc(var(--animate-duration) * 0.8);
  animation-duration: calc(var(--animate-duration) * 0.8);
}

.animate__animated.animate__slow {
  -webkit-animation-duration: calc(1s * 2);
  animation-duration: calc(1s * 2);
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: calc(var(--animate-duration) * 2);
}

.animate__animated.animate__slower {
  -webkit-animation-duration: calc(1s * 3);
  animation-duration: calc(1s * 3);
  -webkit-animation-duration: calc(var(--animate-duration) * 3);
  animation-duration: calc(var(--animate-duration) * 3);
}

@media print,
(prefers-reduced-motion: reduce) {
  .animate__animated {
    -webkit-animation-duration: 1ms !important;
    animation-duration: 1ms !important;
    -webkit-transition-duration: 1ms !important;
    transition-duration: 1ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
  }

  .animate__animated[class*='Out'] {
    opacity: 0;
  }
}

.animate__animated.animate__flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip;
}

@keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.animate__heartBeat {
  -webkit-animation-name: heartBeat;
  animation-name: heartBeat;
  -webkit-animation-duration: calc(1s * 1.3);
  animation-duration: calc(1s * 1.3);
  -webkit-animation-duration: calc(var(--animate-duration) * 1.3);
  animation-duration: calc(var(--animate-duration) * 1.3);
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
.contacts {
  min-height: 25em;

  &__filter {
    // padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    width: 100%;
    margin-right: 8px;
    display: inherit;
    margin-bottom: -1em;

    @include mq-down(md) {
      display: block;
      padding-top: 1rem;
    }

    &__search {
      margin-left: 0.5rem;
    }

    &__select {
      top: 0.85rem;

      @include mq-down(md) {
        top: 0em;
        padding-top: 0.5em;
        margin-left: 0.5em;
        padding-bottom: 1em;
      }
    }
  }
}



.contacts {
  min-height: 25em;

  &__filter {
    // padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    width: 100%;
    margin-right: 8px;
    display: inherit;
    margin-bottom: -1em;

    @include mq-down(md) {
      display: block;
      padding-top: 1rem;
    }

    &__search {
      margin-left: 0.5rem;
    }

    &__select {
      top: 0.85rem;

      @include mq-down(md) {
        top: 0em;
        padding-top: 0.5em;
        margin-left: 0.5em;
        padding-bottom: 1em;
      }
    }
  }
}