// Autocomplete Combobox

.autocomplete {
  position:absolute;
  min-width:100%;
  z-index:z('content-overlay');
  box-shadow: $box-shadow-elevated;
  background-color:#FFF;
  border:1px solid $gray-lighter;
  border-top:0;
  overflow: auto;
  color:$body-color;

  &__list {
    list-style: none;
    margin:0;
  }
  &__item {
    padding:.5em;
    cursor: pointer;

    @include hover-focus {
      color:#FFF;
      background-color:$brand-secondary;
    }
  }

  @include mq(lg){
    font-size:.875rem;
  }
}