// Tooltip Component
.tooltip {
  background-color: #FFF;
  color: $gray-dark;
  padding: 0.5rem;
  border: 1px solid $gray-lighter;

  &__headline {
    color: $brand-secondary;
    font-size: 1rem;
  }
  &__date {
    color: $brand-secondary;
    font-size: 0.85rem;
    text-align:right;
  }
}