// Guided Tour
.tour {
  @include mq-down(md) {
    display: none;
  }
  &__textbox {
    padding-top: 1em;
    padding-right: 1em;
  }
  &__btn {
    text-align: center;
    padding-top: 1em;

    .btn {
      width: 18em;
    }
  }
}
