// Statistic Information Container
.stats {
  &__header {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    padding-right: 38px;
    padding-right:calc(#{$box-toggle-arrow-size} - #{$box-padding});
    border-bottom: 1px solid $gray-lighter;

    @include mq(md) {
      padding-right: 0;
    }
  }

  &__title {
    font-weight: 400;
    font-size: 1.375rem;
    margin: 0;
  }

  &__subtitle {
    font-size: .75rem;
    text-transform: uppercase;
    color: $brand-secondary;
  }

  &__barCharts {
    font-size: 0.85rem;
    position: relative;
    width: 106%;
    height: 250px;
    left: -6%;
  }

  &__lineCharts {
    font-size: 0.85rem;
    position: relative;
    width: 106%;
    height: 300px;
    left: -6%;
  }

  &__barChartTurnover {
    position: relative;
    width: 100%;
    height: 260px;
    left: 0%;
  }

  &__barChartEmployee {
    position: relative;
    width: 100%;
    height: 260px;
    left: 0%;
  }

  &__barChartEngagement {
    position: relative;
    width: 103%;
    height: 250px;
    left: -3%
  }

  &__barChartsAnalysis {
    position: relative;
    width: 103%;
    height: 300px;
    left: -3%;
  }

  &__speedoChart {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 1rem;
    padding-left:1rem;
  }

  &__speedoChartText {
    font-size: 0.75rem;
  }

  &__pieCharts {
    position: relative;
    width: 100%;
    height: 250px;
  }

  &__cloudMAP {
    position: relative;
    width: 100%;
    height: 250px;
  }

  &__barChartRanking {
    font-size: 0.85rem;
    position: relative;
    width: 106%;
    height: 400px;
    left: -6%;
  }

  &__turnEmpTop {
    padding-bottom: 1rem;
    // padding-right: 38px;
    // border-bottom: 1px solid $gray-lighter;
    display: inline-block;
    
    @include mq(md) {
      padding-right: 0;
    }
  }
  &__turnEmpBottom {
    padding-top: 0.5rem;
    // padding-right: 38px;
    display: inline-block;
    
    @include mq(md) {
      padding-right: 0;
    }
  }

  &__turnoverhr {
    display: block;
    unicode-bidi: isolate;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: auto;
    margin-inline-end: auto;
    overflow: hidden;
    border-bottom: 1px solid $gray-lighter;
  }
  
  &__turnover {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.375rem;

    .icon {
      height: 4em;
      width: 4em;
    }

    &__detail {
      padding-left:1rem;  
    }
  }
  




  .trend {
    font-size: 75%;
    padding-left: .5rem;

    &--positive {
      color: $state-success;
    }

    &--negative {
      color: $state-error;
    }
  }
}