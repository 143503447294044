// News Article Component
.article {
  background-color: #fff;
  padding: 0.5rem;
  box-shadow: $box-shadow-default;
  position: relative;
  z-index: 1;
  border-left: 7px solid transparent;
  min-height: 90px;
  border: 1px solid $gray-lighter;
  border-radius: 0.25rem;
  &__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    padding-left: 0;
  }
  &__header {
    margin-top: 7px;
    display: flex;
    flex-direction: column;
    min-width: 100%;
    min-width: fit-content;
    flex: 1;
  }
  &__title {
    display: flex;
    flex: 1;
    font-weight: 300;
    cursor: pointer;
    font-size: 1rem;
    max-width: 85%;
  }
  &__meta {
    order: -1;
    // display: flex;
    flex-direction: row;
    display: table;
  }
  &__category {
    font-size: 0.75rem;
    text-transform: uppercase;
    color: $brand-secondary;
    position: static;
  }
  &__company {
    font-size: 0.75rem;
    text-transform: uppercase;
    color: $brand-secondary;
    position: relative;
    top: 10px;
    left: 10px;
    margin: 0 40px 0 0;
  }
  &__date {
    margin-top: 6px;
    margin-right: 5px;
    font-size: 0.8rem;
    position: absolute;
    right: 0;
    top: 0;
  }
  &__sources {
    font-size: 0.75rem;
    text-transform: uppercase;
    color: $gray-light;
    &__more {
      padding-left: 0.1875rem;
    }
  }
  &__social_stats {
    text-transform: uppercase;
    padding-left: 2.5rem;
    font-size: 0.75rem;
  }
  &__from {
    padding-right: 0.1875rem;
    font-size: 0.75rem;
    text-transform: uppercase;
  }
  &__company {
    order: -1;
    font-weight: 500;
    margin-bottom: 1em;
    padding: 0px 130px 0 0; // space for abs positioned date
    &__name {
      @include hyphenate;
      color: $brand-secondary;
    }
    &__more {
      color: $gray-light;
      padding-left: 0.1875rem;
    }
  }
  &__togglebtn {
    display: block;
    position: absolute;
    right: 0rem;
    bottom: -0.75rem;
    border: 0;
    padding: 0;
    background: none;
    width: 60px;
    height: 60px;
    color: $brand-primary;
    overflow: hidden;
    @include mq(md) {
      width: 40px;
      height: 40px;
    }
    .icon {
      display: block;
      width: 100%;
      height: 100%;
      transform: rotate(0deg);
      transform-origin: 50% 50%;
    }
  } // Content Styles.
  &__content {
    display: none;
  }
  &__excerpt {
    padding: 0.5rem 0;
    p {
      display: flex;
      line-height: 1.5rem;
      max-height: 4.5rem;
      overflow: hidden;
    }
  } // Unread State
  &--unread {
    border-left-color: $gray-lighter;
    border-left-width: 0.5rem;
    border-left .article__title {
      color: $body-color;
      font-weight: 500;
    }
    &.article--trigger {
      border-left-color: $brand-primary;
    }
  } //Expanded State (Mock)
  &--expanded {
    .article__content {
      display: block;
      min-width: 80% !important; //in order to not be bigger than 100% width when expandend
      padding-right: 0 !important; //in order to not be bigger than 100% width when expandend
    }
    .article__togglebtn .icon {
      transform: rotate(180deg);
    }
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    &__title {
      padding-bottom: 1rem;
    }
  } // Wide Layout
  @include mq(md) {
    &__title {
      min-width: 250px;
      padding-right: 0px;
      max-width: 100%;
    }
    &__header {
      min-width: auto;
      max-width: calc(100% - 150px);
    }
    &__company {
      width: 25px;
      padding-right: 0;
    }
    &--expanded .article__content {
      margin-left: 65px;
      min-width: 100%;
      padding-right: 10%;
    }
  }
}

// Pre-rendered Shadow on Hover
/*
.article {
  cursor:pointer;

  &:hover {
    &::after {
      opacity:1;
    }
  }
  &::after {
    display:block;
    content:'';
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:-1;
    opacity:0;
    box-shadow: $box-shadow-elevated;
    transition: opacity .3s $animation-curve-default;
  }
}
*/
