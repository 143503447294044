// Leadsettings  in LeadSettings.jsx
.leadsettings {
  font-weight: 400;
  font-size: 0.875rem;
  display: flex;

  &__companies {
    font-weight: 600;
  }

  &__header {
    color: $gray-light;
  }

  &__list {
    list-style-type: none;
    margin-bottom: 0;
    margin-top: 0;
  }

  &__item {
    margin-bottom: $spacing-y;
  }

  // &__edit {
  //   text-align: right;
  //   flex: auto;
  // }
  &__action {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-decoration: none;
    color: #fff;
    border-radius: 50%;
    padding: 5px;
    transition: background-color 0.2s linear,
      transform 0.2s $animation-curve-default;
    text-align: center;
    transform: scale(1);
    overflow: hidden;
    cursor: pointer;

    @include hover-focus {
      transform: scale(1.1);
    }

    .icon {
      width: 18px;
      height: 18px;
    }

    &--edit {
      background-color: $gray-light;
      margin-top: -0.25rem;
      margin-left: 2rem;

      @include hover-focus {
        background-color: $brand-primary;
      }
    }
    &--download {
      background-color: $gray-light;
      margin-top: -0.25rem;
      margin-left: 0.5rem;

      @include hover-focus {
        background-color: $brand-primary;
      }
    }
  }
}
