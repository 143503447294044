// News Article Component
.message {
  background-color: #fff;
  padding-right: 0.5rem;
  position: relative;
  z-index: 1;
  border-left: 7px solid transparent;
  font-size: 12px !important;
  // min-height: 140px;
  min-height: 83px;
  // border-radius: 0.25rem;

  &__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    padding-left: 0;
  }

  &__header {
    margin-top: 9px;
    display: flex;
    flex-direction: column;
    // min-width: 100%;
    min-width: fit-content;
    flex: 1;
    cursor:default;
  }

  &__title {
    color: $gray-darkest;
    display: flex;
    // flex: 1;
    font-weight: 600;
    font-size: 0.95rem;
  }

  &__meta {
    // order: -1;
    display: flex;
    flex-direction: row;
    line-height: 1;
    position: absolute;
    bottom: -1.5rem;

    @include mq-down(md) {
      position: relative;
      bottom: 0.5rem;
    }
  }

  &__firstmessage {
    font-size: 0.875rem;
    // order: -1;
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: -1.5rem;

    @include mq-down(md) {
      position: relative;
      bottom: 0.5rem;
    }
  }
  
  &__messages {
    width: 96%;
    border-bottom: 1px solid $gray-lighter !important;
    border-top: 0px !important;
    padding-top: 1.5em;
    padding-bottom: 1.5em;

    &__title {
      width: 96%;
      font-size: 0.875rem;
      font-weight: 500;
    }

    &__body {
        padding: 0px 10px;
        font-size: 14px;
        text-align: justify;
    }
    &__body::first-line {
      text-align-last: start;
      -moz-text-align-last: start;
    }
    
    
  }

  &__category {
    font-size: 0.75rem;
    font-weight: 600;
    color: $brand-secondary;
    position: static;
  }

  &__sentiment {
    font-size: 0.75rem;
    font-weight: 600;
    color: $gray-light;
    position: static;
  }

  &__seperator {
    font-size: 0.75rem;
    color: $gray-light;
    position: static;
    font-weight: 400;
  }

  &__date {
    font-size: 0.75rem;
    color: $gray-light;
    position: static;
  }

  &__sources {
    font-size: 0.75rem;
    // text-transform: uppercase;
    color: $gray-light;

    &__more {
      padding-left: 0.1875rem;
    }
  }

  &__social_stats {
    text-transform: uppercase;
    padding-left: 2.5rem;
    font-size: 0.75rem;
  }

  &__from {
    padding-right: 0.1875rem;
    font-size: 0.75rem;
    color: $gray-light;
  }

  &__company {
    font-size: 0.75rem;
    color: $gray-light;
    position: relative;
    top: 9px;
    left: 5px;
    margin: 0 26px 0 0;
    order: -1;
    margin-bottom: 1em;
    padding: 0px 130px 0 0; // space for abs positioned date

    &__name {
      @include hyphenate;
      color: $gray-light;
      font-size: 0.75rem;
    }

    &__more {
      color: $gray-light;
      padding-left: 0.1875rem;
    }
  }

  &__dragndrop {
    margin-top: 20px;
    margin-left: 10px;

    .icon {
      stroke: $gray-light;
      width: 2em;
      height: 2em;

      &:hover {
        stroke: $gray-dark;
        cursor: pointer;
      }
    }
  }

  &__changecategory {
    padding: 1em;
    background-color: #FFF;
    border: 1px solid $gray-light;
    z-index: 9999;
    display: block;
    position: absolute;


  }

  &__sharing {
    color: $gray-light;
    font-size: 0.75rem;

    @include mq-down(md) {
      padding-left: 0rem;
    }

    &--middle {
      display: inline-block;
      vertical-align: middle;
      line-height: normal;
    }
  }

  &__symbol {
    margin-top: 1rem;

    &__youtube {
      display: inline-block;
      text-decoration: none;
      margin-top: 0.5rem;
      text-align: center;
      overflow: hidden;

      .icon {
        width: 3em;
        height: 1.7em;
      }
    }

    &__link {
      display: inline-block;
      width: 32px;
      height: 32px;
      line-height: 30px;
      text-decoration: none;
      color: #fff;
      border-radius: 50%;
      padding: 5px;
      // margin-right: .4rem;
      margin-top: 0.2rem;
      // transition: background-color .2s linear, transform .2s $animation-curve-default;
      text-align: center;
      // transform: scale(1);
      overflow: hidden;

      .icon {
        width: 18px;
        height: 18px;
      }

      &--news {
        background-color: $newspaper-color;
      }

      &--linkedin {
        background-color: $linkedin-color;
      }

      &--facebook {
        background-color: $facebook-color;
        padding: 7px;
      }

      &--twitter {
        background-color: $twitter-color;
        padding: 6px;
      }

      &--youtube {
        background-color: $youtube-color;
        padding: 6px;
      }
    }
  }

  &__togglebtn {
    display: block;
    position: absolute;
    right: 0rem;
    // bottom: -0.75rem;
    top: 1.2rem;
    border: 0;
    padding: 1px;
    background: none;
    width: 60px;
    height: 60px;
    color: $gray-dark;
    overflow: hidden;

    @include mq(md) {
      width: 40px;
      height: 40px;
    }

    .icon {
      display: block;
      width: 100%;
      height: 100%;
      transform: rotate(0deg);
      transform-origin: 50% 50%;
    }
  }

  // Content Styles.
  &__content {
    display: none;
  }

  &__excerpt {
    font-size: 0.85rem;
    color: $gray-dark;
    padding: 0.5rem 0rem;

    @include mq-down(md) {
      padding: 0.5rem 0rem;
    }

    p {
      display: inline;
      line-height: 1.5rem;
      max-height: 4.5rem;
      overflow: hidden;
    }
  }

  // Unread State
  &--unread {
    border-left-color: $gray-lighter;
    border-left-width: 0.5rem;

    border-left .message__title {
      color: $body-color;
      // font-weight: 500;
    }

    &.message--trigger {
      border-left-color: $brand-primary;
    }
  }

  &--trigger {
    border-left-color: $brand-primary;
  }

&--active {
  background-color: $gray-lightest;
}

  //Expanded State (Mock)
  &--expanded {
    .message__content {
      display: block;
    }

    .message__togglebtn .icon {
      transform: rotate(180deg);
    }

    .message__meta {
      position: initial;
    }
        .message__firstmessage {
          position: initial;
        }
  }

  @media screen and (-ms-high-contrast: active),
  (-ms-high-contrast: none) {
    &__title {
      padding-bottom: 1rem;
      display: table;
    }
  }

  // Wide Layout
  @include mq(md) {

    // min-height: 75px;
    &__title {
      min-width: 250px;
      padding-right: 0px;
    }

    &__header {
      display: table;
      min-width: 70%;
      max-width: calc(100% - 150px);
    }

    &__company {
      width: 50px;
      padding-right: 0;
    }

    &--expanded .message__content {
      margin-left: 75px;
      min-width: 100%;
      padding-right: 10%;
    }

    &__meta {
      position: inherit;
      padding-bottom: 0.5rem;
      bottom: -1rem;
    }
    &__firstmessage {
      position: inherit;
      padding-bottom: 0.75rem;
      bottom: -1rem;
      padding-top: 0.25rem;
    }
  }

  @include mq(lg) {
    &__header {
      display: table;
      min-width: 50%;
      max-width: calc(100% - 150px);
    }
  }
}

.messagechat {
  &__container {
    border-left: 1px solid $gray-lighter;
    border-right: 1px solid $gray-lighter;
    max-height: 80vh;
    overflow-y: overlay;
    overflow-x: hidden;
  }

  &__body {
    max-height: 72vh;
    height: auto;
    overflow-y: overlay;
    overflow-x: hidden;
  }

  &__list {
    list-style-type: none;
    margin: 0;
  }

  &__item {
    border-bottom: 1px solid #d8d8d8;
  }
}

@media screen and (max-width: 1456px) {

  .message {
    &__title {

      &__contacts {
        min-width: 150px;
      }

    }

   }

  }

   @media screen and (max-width: 1022px) {
     
     .messagechat {
       &__container {
         height: auto;

       }

       &__body {
         height: auto;
       }
     }
}

   @media screen and (max-width: 767px) {
    .messagewidgetcontainer {
      padding: 0 !important;
    }
     .message {
       &--expanded {
         padding: 0 !important;
         border-left-width: 0 !important;
       }
       &--expanded .message__content {
         margin:0 !important;
       }
     }
   }



.messagecategory {
  &__header {
    padding-bottom: 0.5em;
  }

  &__item {
    padding: 0.75em;
    border-top: 1px solid $gray-lighter;

    &__text {
      cursor: pointer;

      &:hover {
        color: $brand-secondary;
      }
    }

    &__edit {
      float: right;
      cursor: pointer;
      margin-top: 3px;

      .icon {
        width: 1em;
        height: 1em;
      }

      &:hover {
        color: $gray-darkest;
      }

    }

    // &--isactive {}
  }

  &__link {
    padding: 0.25em 2em 0.25em 0;

    .icon {
      width: 1em;
      height: 1em;
      margin-bottom: -2px !important;
      margin-right: 0.5em;
    }

    &--add {
      display: flex;
      align-items: center;
      color: $link-color;
      transition: all 0.2s $animation-curve-default;
      cursor: pointer;

      @include mq-down(md) {
        padding-bottom: 1rem;
      }

      &:hover {
        padding-left: 0.5em;
      }

      .icon {
        width: 1.5em;
        height: 1.5em;
        margin-right: 0.5em;
      }
    }


  }
}

.grid-container-message {
  padding-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "search search search search search search search search search search search icon";
}

.search-message {
  grid-area: search;
}



.icon-message {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  grid-area: icon;

  div.emoji {
    width: 40px;

    .icon.icon--emoji {
      cursor: pointer;
      stroke: #d8d8d8;
      margin: 20px 20px;
      width: 25px;
      height: 25px;

    }

    .icon.icon--emoji:hover {
      stroke: $brand-blue;
    }
  }

  div.send {
    width: 40px;

    .icon.icon--send {

      stroke: #d8d8d8;
      margin: 20px 20px;
      width: 25px;
      height: 25px;
      transform: rotate(44deg);
    }
  }

}

.icon-message {
  div.send.enabled {
    .icon.icon--send {
      cursor: pointer;
      stroke: $brand-blue;

    }
  }

  div.emoji.enabled {
    .icon.icon--emoji {
      stroke: $brand-blue;

    }
  }
}

.emoji-picker {

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;

}

.emoji-picker-react {
  width: 100% !important
}

.textfield__input__messages {
      border-bottom: 1px solid #d8d8d8;
}

.emoji > button div.native {
  font-size: 14px !important;
  margin: 2px !important;
}

.emoji-picker-react .emoji-group:before {
  font-size: 12px !important;
}

.emoji-picker-react .emoji-categories button {
  background-size: 19px !important;
}
.emoji-picker-react .native {
  font-size: 19px !important;
}
// Pre-rendered Shadow on Hover
/*
.message {
  cursor:pointer;

  &:hover {
    &::after {
      opacity:1;
    }
  }
  &::after {
    display:block;
    content:'';
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:-1;
    opacity:0;
    box-shadow: $box-shadow-elevated;
    transition: opacity .3s $animation-curve-default;
  }
}
*/
