.mapboxgl-popup {
  width: 30%;
}

.control-panel {
  position: absolute;
  bottom: 42px;
  right: 0;
  max-width: 320px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  padding: 12px 24px;
  margin: 20px;
  font-size: 10px;
  line-height: 2;
  color: #6b6b76;
  text-transform: uppercase;
  outline: none;

  .input {
    input {
      cursor: pointer;
      margin-left: 10px;
    }
  }
  @include mq-down(md) {
    bottom: -19px;
    right: -20px;
  }
}

// popup
$strategycolor: #005fa5;
$performancecolor: #ff6559;
$financecolor: #4a4a4a;
$mergeracquisitionsalecolor: #498cbd;
$employeecolor: #00c49f;
$legalcolor: #df8a13;
$publiccolor: #d0021b;
$defaultcolor: $strategycolor;

$colors: $strategycolor, $performancecolor, $financecolor,
  $mergeracquisitionsalecolor, $employeecolor, $legalcolor, $publiccolor,
  $defaultcolor;
$classes: "Strategy", "Performance", "Finance", "MergerAcquisitionSale",
  "Employee", "Legal", "Public", "default";

@for $i from 1 through length($classes) {
  .pinPopup-#{nth($classes, $i)} {
    .mapboxgl-popup-content {
      border: 1.5px solid nth($colors, $i);
    }
    svg {
      fill: nth($colors, $i) !important;
    }
  }

  .mapboxgl-popup-anchor-top.pinPopup-#{nth($classes, $i)} {
    .mapboxgl-popup-tip {
      border-bottom-color: nth($colors, $i) !important;
    }
  }
  .mapboxgl-popup-anchor-bottom.pinPopup-#{nth($classes, $i)} {
    .mapboxgl-popup-tip {
      border-top-color: nth($colors, $i) !important;
    }
  }
  .mapboxgl-popup-anchor-right.pinPopup-#{nth($classes, $i)} {
    .mapboxgl-popup-tip {
      border-left-color: nth($colors, $i) !important;
    }
  }
  .mapboxgl-popup-anchor-left.pinPopup-#{nth($classes, $i)} {
    .mapboxgl-popup-tip {
      border-right-color: nth($colors, $i) !important;
    }
  }
  .mapboxgl-popup-anchor-top-left.pinPopup-#{nth($classes, $i)} {
    .mapboxgl-popup-tip {
      border-bottom-color: nth($colors, $i) !important;
    }
  }
  .mapboxgl-popup-anchor-top-right.pinPopup-#{nth($classes, $i)} {
    .mapboxgl-popup-tip {
      border-bottom-color: nth($colors, $i) !important;
    }
  }
  .mapboxgl-popup-anchor-bottom-left.pinPopup-#{nth($classes, $i)} {
    .mapboxgl-popup-tip {
      border-top-color: nth($colors, $i) !important;
    }
  }
  .mapboxgl-popup-anchor-bottom-right.pinPopup-#{nth($classes, $i)} {
    .mapboxgl-popup-tip {
      border-top-color: nth($colors, $i) !important;
    }
  }

  // input checkbox

  .control {
    input {
      &:checked ~ .control_indicator-#{nth($classes, $i)} {
        background: nth($colors, $i) !important;
      }
    }
  }

  // input checkbox
}

// popup

// categories
.category-strategy-map {
  color: $strategycolor;
}
.category-performance-map {
  color: $performancecolor;
}
.category-finance-map {
  color: $financecolor;
}
.category-mergeracquisitionsale-map {
  color: $mergeracquisitionsalecolor;
}
.category-employee-map {
  color: $employeecolor;
}
.category-legal-map {
  color: $legalcolor;
}
.category-public-map {
  color: $publiccolor;
}
.category-default-map {
  color: $strategycolor;
}
// categories

// input checkbox
.control {
  font-family: arial;
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 13px;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
}

.control_indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
  border: 0px solid #000000;
  border-radius: 0px;
}

.control {
  &:hover input ~ .control_indicator {
    background: #cccccc;
  }

  input {
    &:focus ~ .control_indicator {
      background: #cccccc;
    }

    &:checked ~ .control_indicator {
      background: grey;
    }
  }

  input {
    &:disabled ~ .control_indicator {
      background: #e6e6e6;
      opacity: 0.6;
      pointer-events: none;
    }
  }
}

.control_indicator:after {
  box-sizing: unset;
  content: "";
  position: absolute;
  display: none;
}

.control input:checked ~ .control_indicator:after {
  display: block;
}

.control-checkbox {
  font-size: 10px;
  .control_indicator:after {
    left: 8px;
    top: 4px;
    width: 3px;
    height: 8px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  input:disabled ~ .control_indicator:after {
    border-color: #7b7b7b;
  }

  .control_indicator::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 4.5rem;
    height: 4.5rem;
    margin-left: -1.3rem;
    margin-top: -1.3rem;
    background: grey;
    border-radius: 3rem;
    opacity: 0.6;
    z-index: 99999;
    transform: scale(0);
  }

  input {
    + .control_indicator::before {
      animation: s-ripple 250ms ease-out;
    }

    &:checked + .control_indicator::before {
      animation-name: s-ripple-dup;
    }
  }
}

@keyframes s-ripple {
  0% {
    transform: scale(0);
  }

  20% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes s-ripple-dup {
  0% {
    transform: scale(0);
  }

  30% {
    transform: scale(1);
  }

  60% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

// input checkbox


// google map - company container 

.googlemap-placeholder-item {
  box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
  border-radius: 4px;
  height: 120px;
  position: relative;
  overflow: hidden;

  div {
    display: none;
  }
}


.googlemap-placeholder-item::before {
  content: '';
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(to right, transparent 0%, #E5E3DF 50%, transparent 100%);
  animation: googlemapload 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}

@keyframes googlemapload {
  from {
    left: -20px;
  }

  to {
    left: 100%;
  }
}
// google map - company container 