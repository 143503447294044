// Open Position List Component

.reports {
  &__list {
    margin: 0;
  }

  &__item {
    display: flex;
    margin-bottom: 0.5rem;
  }
}

.report {
  &__created {
    flex: none;
    width: 5rem;

    line-height: 1.5rem;
    font-size: 0.9rem;
    font-weight: 300;
  }

  &__title {
    flex: 1;
    color: $gray-dark;
    line-height: 1.5rem;
    font-weight: 400;
  }
}
