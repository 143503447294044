// Company Detail
.company {
  &__header {
    font-size: 1rem;
    padding-bottom: $spacing-y;
    margin-bottom: $spacing-y;
  }

  // Logo for md Display
  &__logo {
    display: none;
    align-items: center;
    justify-content: center;
    padding-right: $spacing-x;

    img {
      max-width: 100%;
      max-height: 120px;
    }
  }

  &__logoblank {
    display: none !important;
  }

  &__title {
    width: 100%;
    font-size: 1.3em;
    font-weight: 500;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__subtitle {
    font-weight: 600;
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
  }

  &__address {
    vertical-align: top;
  }

  &__headline {
    display: flex;
  }

  &__share {
    @include hover-focus {
      cursor: pointer;
      color: $brand-primary;
    }

    .icon {
      height: 1.2em;
      width: 1em;
      margin-bottom: -0.2em;
      margin-left: 0.5em;
    }
  }

  &__category {
    font-size: 1rem;
    color: $gray;

    @include hover-focus {
      cursor: pointer;
      color: $brand-primary;
    }
  }

  &__subword {
    font-size: 0.6rem;
    margin-left: 0.1em;
  }

  &__contact {
    @include truncate;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__map {
    @include truncate;
    display: none;
  }

  &__table {
    width: 100%;
    border-collapse: collapse;
    border: none;
    vertical-align: top;
  }

  &__td {
    width: 1%;
    padding-right: 0.5rem;
    white-space: nowrap;
  }

  &__tooltip {
    width: 20em;
    text-align: left;
    white-space: normal !important; // otherwise takes nowrap from box__title
    margin-left: 0.5em;
  }

  @include mq(lg) {
    &__logo {
      display: flex;
      flex: 0 1 20%;
      max-width: 9.5%;
      padding-right: 1.25rem;
      margin: 0;
      max-height: 120px;
    }

    @media screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
      &__logo {
        display: block;
        flex: 0 1 20%;
        max-width: 9.5%;
        padding-right: 1.25rem;
        margin: 0;
        max-height: 120px;
      }
    }

    &__logoblank {
      display: block !important;
      align-items: center;
      max-width: 100%;
      max-height: 120px;
      margin-right: 1.25rem;
    }
  }

  @include mq(xl) {
    &__details {
      display: block;
      padding-left: $spacing-x;
      flex: 1 1 20%;
    }

    &__map {
      display: block;
    }
  }

  @include mq(md) {
    &__header {
      font-size: 1.1rem;
      font-weight: 200;
    }

    &__info {
      flex: 1 0 80%;
    }

    // &__contact {
    //   min-width: 60%;
    // }

    &__stats {

      // make boxes equal height
      .box,
      [class^="col"] {
        display: flex;
        flex-direction: column;
      }

      .box,
      .box__body {
        flex: 1 0 auto;
        padding: 0.5rem 0.5rem 0.25rem 0.5rem;
      }
    }
  }

  &__filterBg {
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s $animation-curve-default;
    margin-left: 1rem;
    margin-top: 2rem;
    padding-right: 0.5em;
    background: $gray-lightest;
    z-index: z("content-overlay");
    margin-right: 1em;
    border: 0px solid #d8d8d8;
    border-radius: 0.25em;

    &.is-expanded {
      border: 1px solid #d8d8d8;
      border-radius: 0.25em;
      margin-top: 2rem;
      max-height: 30rem;
      overflow: hidden;
      transition: max-height 0.5s $animation-curve-default;
      background: $gray-lightest;
      padding-bottom: 1rem;

      @include mq-down(md) {
        max-height: 55rem;
        margin-left: 0rem;
      }
    }
  }

  &__search {

    padding-top: 1.5em;

    &__searchbtn {
      margin-bottom: 0;
      cursor: pointer;
      margin-right: 8em;
    }

    &__message {
      padding-top: 1.5em;
    }

    &__checkbox {
      padding-top: 0.25em;
    }

    &__download {
      float: right;

      .icon {
        height: 1.5em;
        width: 1.5em;
        margin-right: 0.75em;
      }
    }


  }

  &__importbuttonsalesforce {
    margin-left: auto;
    margin-bottom: 10px;
  }

  &__labelimportdata {
    margin-left: 10px;
  }

  &__checkboximportdata {
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
    cursor: pointer;
  }
  &__turnoverBadge {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.375rem;

    .icon {
      height: 3em;
      width: 3em;
    }

    &__detail {
      padding-left: 1rem;
    }
  }

  &__businessActivityBadge {
    display: inline-flex;

    &__icon {
      display: inline-block;
      vertical-align: middle;

      .icon {
        color: $brand-blue;
        height: 3em;
        width: 3em;
        margin-top: 9px;
      }
    }

    &__detail {
      padding-left: 1rem;
      font-size: 0.75em;
    }
  }

  &__industryBadge {
    display: inline-flex;

    &__icon {
      display: inline-block;
      vertical-align: middle;

      .icon {
        color: $brand-blue;
        height: 2.5em;
        width: 2.5em;
        margin-top: 13px;
      }
    }

    &__detail {
      padding-left: 1rem;
      font-size: 0.75em;
    }
  }

  &__documents {
    margin-top: -0.5em;
  }

}