// badge Component
// used e.g. in notification counts
.badge {
  display: inline-block;
  white-space: nowrap;
  width: 1.75em;
  height: 1.75em;
  line-height: 1.75;
  color: #FFF;
  background-color: $brand-primary;
  border-radius: 50%;
  font-size: .857142857em;
  text-align: center;
}