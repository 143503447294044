// Main Typography Styles
//Headlines
h1,
.h1 {
  font-weight: 300;
  font-size: $font-size-h1;
  line-height: 1.333;
  margin-bottom: $spacing-y;
}

h2,
.h2 {
  font-weight: 500;
  font-size: $font-size-h2;
  line-height: 1.333;
}

h3,
.h3 {
  font-weight: 400;
  font-size: $font-size-h3;
  line-height: 1.333;
}

.caption {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 0.875rem; //14px
  margin-bottom: 0.25rem; //12px
}

//
// Links
//
a,
.a {
  color: $link-color;
  text-decoration: $link-decoration;

  &:hover,
  &:focus {
    outline: 0;
    text-decoration: $link-hover-decoration;
  }
  &__stealth {
    color: $gray-dark;
  }
}

// Emphasis and Highlight
em {
  font-style: normal;
}

em,
.highlight {
  color: $brand-primary;
}

strong {
  font-weight: 500;
}

// Horizontal Rulers
hr {
  margin-top: 3rem;
}

// Space between Sections
.newsection {
  margin-top: 1rem;
}

// Lists
.list--none {
  list-style: none;
  padding-left: 0;
}

// Text Utilities
.text--left {
  text-align: left;
}

.text--center {
  text-align: center;
}

.text--right {
  text-align: right;
}

.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}
