.portfolio {
  padding-top: 3.5rem;
  padding-bottom: 1em;
  width: 100%;
  margin-right: 8px;
  display: inherit;
  margin-bottom: -1em;
  @include mq-down(md) {
    display: block;
    padding-top: 2rem;
  }
  &__search {
    margin-left: 0.5rem;
  }
  &__filter {
    top: 0.15em;
    @include mq-down(md) {
      top: 0em;
      padding-top: 0.5em;
      margin-left: 0.5em;
      padding-bottom: 1em;
    }
  }
  &__graph {
    font-size: 0.875rem;
  }
  &__add__text {
    white-space: normal;
  }
  &__box {
    padding: 1em;
    background-color: #fff;
    margin-top: -8px;
  }

  &__list {
    padding-bottom: 3.75em;
  }
}
