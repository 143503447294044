// Package Component

.package {
  background-color:#FFF;
  padding:$box-padding;
  box-shadow:$box-shadow-default;
  margin-bottom: $spacing-y;

  &--current {
    background-color:$brand-secondary;
    color:#FFF;
  }

  &__inner {
    position:relative;
  }

  &__title {
    font-size:1.25rem;
  }

  &__features {
    list-style: none;
    width:100%;
  }

  &__feature {
    padding:.125rem 0;

    .icon {
      width:1.25em;
      height: 1.25em;
      margin-right:.25em;
      position:relative;
      top:.25em;
    }

    &--disabled {
      text-decoration: line-through;
      opacity:.65;
    }
  }
  
  &__price {
    position:relative;
    font-size:3.375rem; //54px
    line-height:1.125;
    margin-bottom:1rem;

    &__unit {
      font-size:.44444em; //24px
      padding-right:.25em;
    }
    &__subline {
      display:block;
      font-size:.22222em; //12px
    }
  }

  &__cta {
    display:block;
    width:100%;
    transition:none;
  }

  @include mq(md){
    &__inner {
      padding-right:160px;
    }
    &__price {
      position:absolute;
      right:0;
      top:0;
      width:160px;
      text-align: right;
    }
    &__features {
      column-count: 2;
      column-gap:1rem;
      margin:0 1rem 0 0;
    }
    &__cta {
      position:absolute;
      right:0;
      bottom:0;
      width:150px;
    }
  }
}