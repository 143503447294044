// Topbar Component
// containers/Topbar.jsx
.topbar {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: z("topbar");
  height: $topbar-height;
  background-color: $brand-secondary;

  @include mq(md) {
    overflow: visible;

    .container {
      padding: 0;
    }
  }

  &__inner {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__left {
    @include mq(lg) {
      width: 16.66%;
      margin-right: 10px;
    }
  }

  &__center {
    flex: 1 0 auto;

    @include mq-down(xl) {
      text-align: -webkit-center;
      padding-left: 3em;
    }
    @include mq-down(lg) {
    }
  }

  // &__searchbar {
  //   position: absolute;
  //   top: 10px;
  // }

  &__right {
    display: flex;
    align-items: center;
    margin-right: -1 * ($spacing-x / 2);
  }

  &__brand {
    display: block;
    color: #fff;
    width: 50px;
    overflow: hidden;

    @include mq(md) {
      width: 280px;
      margin-right: 2rem;
    }
  }

  &__user {
    color: #fff;
    display: none;

    @include mq(lg) {
      display: block;
    }
  }

  &__btn {
    display: inline-block;
    width: $topbar-height;
    height: $topbar-height;
    line-height: $topbar-height;
    border: 0;
    text-align: center;
    position: relative;
    background: none;
    color: #fff;

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 34px;
      height: 34px;
    }

    &--search {
      .icon {
        //correct optical misalignment
        margin-top: 3px;
      }

      @include mq(md) {
        display: none;
      }
    }

    &--menu {
      @include mq(lg) {
        display: none;
      }
    }

    &--logout {
      @include mq-down(lg) {
        display: none;
      }
    }
  }
}
