// News Page (Home)

.news {
  &__list {
    list-style-type: none;
    margin: 0;
  }

  &__item {
    margin-bottom: $spacing-y;
  }

  &__sorting {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    margin-bottom: 1rem;

    &__label {
      padding-right: 0.5rem;
      text-transform: uppercase;
      font-size: 0.875rem;
    }

    &__option {
      border: 0;
      padding: 0.5em 1em;
      background: transparent;
      font-weight: normal;

      &:hover {
        color: $brand-primary;
      }

      &:not(:last-child) {
        border-right: 1px solid $gray-lighter;
      }

      &--active {
        font-weight: 500;

        &:hover {
          color: $body-color;
        }
      }
    }

    @include mq(lg) {
      font-size: 0.875rem;
      &__option {
        padding: 0.25em 0.5em;
      }
    }
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;

    &__message {
      padding-left: 1rem;
      text-transform: uppercase;
      font-size: 0.875rem;
      font-weight: 500;
      color: $brand-secondary;
    }
  }
}
