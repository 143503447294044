// "Show Less" Component

.dashboardfilter {
  text-align: center;

  &__togglebtn {
    color: $brand-primary;
    text-transform: uppercase;
    font-size: 0.875rem;
    line-height: 1;
    padding: 3px 6px;

    > span {
      //hack to fix icon alignment in safari
      padding: 6px 0;
    }

    .icon {
      font-size: 1.5rem;
      margin: 0 0.25rem;
      transform: rotate(0);
    }
  }

  &--expanded {
    .icon--chevron-down {
      transform: rotate(-180deg);
    }
  }
}
