// Navigation Component
// containers/Navigation.jsx
.nav {
  position: fixed;
  z-index: z("navigation");
  top: 0;
  right: 0;
  max-width: 100%;
  width: 100%;
  height: 100vh;
  padding-top: $topbar-height;
  background-color: $brand-secondary;
  color: #fff;
  font-size: 1.5rem;
  overflow: hidden;
  overflow-y: auto;
  transform: translate3d(100%, 0, 0);
  transition: transform 0.3s $animation-curve-default;
  margin-left: -0.9375rem; //bring back to left

  .menu-open & {
    transform: translate3d(0, 0, 0);
  }

  @include mq(lg) {
    position: static;
    height: auto;
    padding-top: 0;
    min-height: 93vh;
  }

  &__inner {
    display: flex;
    height: 100%;
    flex-direction: column;
    padding-bottom: $spacing-y / 2;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: -0.5rem 0rem 0rem 0rem;
    max-width: 100%;
  }

  &__hr {
    border-bottom: 1px solid $gray-lighter;
    margin-left: 0.9375rem;
  }

  &__item {
    font-weight: 400;

    &--active {
      font-weight: 500;
      color: #fff;

      @include mq(lg) {
        color: $brand-secondary;
      }
    }

    &--logout {
      padding: 20px 0;

      .icon {
        color: $white;
        float: right;
        font-size: 40px;
      }

      @include mq(lg) {
        display: none;
      }
    }
  }

  &__link {
    display: block;
    padding: 0.75em 0;
    position: relative;
    color: currentColor;

    .icon {
      height: 1em;
      width: 1em;
      margin-bottom: -2px;
      margin-right: 0.75em;
    }

    @include hover-focus {
      text-decoration: none;
      color: $link-color;
    }
  }

  @include mq(md) {
    width: $nav-width-md;

    &__inner.container {
      padding: 0 $spacing-y $spacing-y;
    }
  }

  @include mq(lg) {
    width: $nav-width-lg;
    right: auto;
    background-color: transparent;
    color: $body-color;
    font-size: 1.125rem;
    color: $gray-dark;
    transform: translate3d(0, 0, 0);
    transition: none;

    &__inner.container {
      padding: $spacing-y 0;
    }
  }

  @include mq(xl) {
    width: $nav-width-xl;
  }
}

// Navigation Menu
.nav__menu {
  border-top: 2px solid currentColor;
  margin-left: 0rem;

  @include mq(lg) {
    margin-left: 0.9375rem;
  }

  .nav__item {
    border-bottom: 2px solid currentColor;
  }

  @include mq(lg) {
    border-top: none;

    .nav__item {
      border-bottom: none;
    }
  }
}

//Navigation Background
.nav__bg {
  background-color: #fff;
}

// Search of Companies, Campaigns and Leads
.nav__search {
  display: none;

  @include mq(lg) {
    display: block;
    border: 1px solid $gray-lighter;
    background-color: $white;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-left: 0.5rem;
    margin-top: 0.5rem;
    max-width: 90%;
    margin-bottom: 0.5rem;
  }
}

// Company & Campaign List
.nav__campaigns {
  position: relative;
  font-size: 1.125rem;
  border-top: 1px solid $gray-lighter;

  &__title {
    padding-top: $spacing-y;
    font-size: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    @include mq(lg) {
      font-size: 1.125rem;
      padding-left: 0.9375rem;
      margin-top: 0rem;
      margin-bottom: 0rem;

      cursor: pointer;
      transform: translate3d(0, 0, 0);

      &::after {
        content: "+";
        font-size: 18px;
        // color: #000;
        transition: transform 0.5s $animation-curve-default;
        position: absolute;
        right: 30px;
        margin-bottom: 0rem;
      }

      &.is-expanded {
        transition: background 0.3s $animation-curve-default;
        background: $gray-lightest;

        &::after {
          content: "-";
          transform: rotate(-360deg);
        }
      }
    }
  }

  &__content {
    // background-color: $brand-blue;
    height: 100%;

    @include mq-down(md) {
      display: flex;
      margin-top: -1rem;
    }

    @include mq(lg) {
      overflow: hidden;
      max-height: 0;
      transition: max-height 0.5s;
      border-top: 0;
      padding-left: 0.9375rem;
      // background:$gray-lightest;
      padding-bottom: 0rem;

      &.is-expanded {
        max-height: 490px;
        overflow: auto;
        transition: background 0.2s $animation-curve-default;
        background: $gray-lightest;
        padding-bottom: 1rem;
      }
    }
  }

  .nav__item {
    border-left: 4px solid transparent;
    padding-left: 0.5rem;
    margin-top: 0.6rem;

    &--unread {
      border-left-color: $brand-primary;
    }

    &__unreadcount {
      padding-left: 0.25rem;
      opacity: 0.5;
    }
  }

  .nav__link {
    padding: 0.25em 2em 0.25em 0;

    .icon {
      width: 1em;
      height: 1em;
      margin-bottom: -2px !important;
      margin-right: 0.5em;
    }

    &--addcompany {
      display: flex;
      align-items: center;
      color: $link-color;
      transition: all 0.2s $animation-curve-default;

      @include mq-down(md) {
        padding-bottom: 1rem;
      }

      &:hover {
        padding-left: 0.5em;
      }

      .icon {
        width: 1.5em;
        height: 1.5em;
        margin-right: 0.5em;
      }
    }


  }

  @include mq(lg) {
    font-size: 0.875rem;

    &__scrollpane {
      display: block;
      position: relative;
      overflow-y: auto;
      overflow-x: hidden;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      -webkit-overflow-scrolling: touch;
    }
  }
}

.nav__logout {
  margin-top: 1rem;
  border-top: 2px solid currentColor;

  @include mq(lg) {
    display: none;
  }
}

.nav__link {
  &--flex {
    display: flex;
    align-items: center;

    .icon--play {
      width: 30px;
      height: 30px;
      margin-left: -7.5px;
      margin-right: 12px;
      margin-top: -5px;
      max-width: 50%;


    }

    span {
      width: 100%;
    }
  }

}