.rcw-conversation-container .rcw-header {
  background-color: $brand-blue !important;
  padding: 0px 0 0px !important;
}

.rcw-header {
  border-radius: 0px 0px 0 0 !important;
}

.rcw-title {
  padding: 15px 0px !important;
  font-size: calc(11.5px + 0.5vw) !important;
}
@include mq(md) {
  .rcw-title {
    font-size: 18px !important;
  }
}

.rcw-launcher {
  background-color: $brand-blue !important;
  margin: 0px !important;
  position: relative !important;
}

.rcw-sender {
  display: none !important;
}

.rcw-messages-container {
  overflow-y: auto !important;
}

@media screen and (max-width: 800px) {
  .rcw-widget-container.rcw-opened {
    height: 70vh !important;
    width: 120% !important;
    margin-top: 230px !important;
    .rcw-conversation-container {
      .rcw-header {
        padding: 0px !important;
        .rcw-close-button {
          right: 5px;
          top: 8px !important;
          width: 28px !important;
          .rcw-close {
            width: 10px !important;
            height: 10px !important;
          }
        }
        .rcw-title {
          margin-left: -30px;
        }
      }
    }
  }
}

@include mq-down(md) {
  .rcw-widget-container.rcw-opened {
    height: 70vh !important;
    width: 120% !important;
    .rcw-conversation-container {
      .rcw-header {
        padding: 0px !important;
        .rcw-close-button {
          right: 5px;
          top: 8px !important;
          width: 28px !important;
          .rcw-close {
            width: 10px !important;
            height: 10px !important;
          }
        }
        .rcw-title {
          margin-left: -30px;
        }
      }
    }
  }
}

.rcw-badge {
  top: 28px !important;
  right: -9px !important;
  line-height: 20px !important;
  border: 2px solid;
  font-size: 15px;
}

.rcw-close-button {
  background-color: $brand-blue !important;
  border: 3px solid white !important;
  top: 10px !important;
  cursor: pointer;
}

.rcw-response {
  width: 100% !important;
  max-width: none !important;
  color: #4a4a4a !important;
  background-color: white !important;
  // box-shadow: 3px 3px lightgrey;
  border-right: 3px solid lightgrey;
  border-bottom: 3px solid lightgrey;
  border-top: 3px solid lightgrey;
  border-radius: 0px !important;

  background: none;
  border: 0;
  box-sizing: border-box;
  // margin: 1em;
  // padding: 1em 2em;

  box-shadow: inset 0 0 0 3px lightgrey;
  color: lightgrey;
  font-size: inherit;
  font-weight: 400;
  position: relative;
  vertical-align: middle;

  &::before,
  &::after {
    box-sizing: inherit;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
  }

  transition: color 0.25s;

  &::before,
  &::after {
    border: 3px solid transparent;
    width: 0;
    height: 0;
  }

  &::before {
    top: 0;
    left: 0;
  }

  &::after {
    bottom: 0;
    right: 0;
  }

  &:hover::before,
  &:hover::after {
    width: 100%;
    height: 100%;
  }

  &:hover::before {
    transition: width 0.25s ease-out, height 0.25s ease-out 0.25s;
  }

  &:hover::after {
    transition: border-color 0s ease-out 0.5s, width 0.25s ease-out 0.5s,
      height 0.25s ease-out 0.75s;
  }
}

.rcw-links {
  &::before,
  &::after {
    content: none;
  }
}

.rcw-links-message-info {
  &:hover {
    box-shadow: inset 3px 0px 0px $state-info, inset -3px 0px 0px $state-info,
      inset 0 3px 0px $state-info, inset 0 -3px 0px $state-info !important;
  }
}

.icon-event-message-info {
  color: $state-info;
}

.rcw-response.event-message-info {
  box-shadow: inset 3px 0px 0px $state-info, inset -3px 0px 0px lightgrey,
    inset 0 3px 0px lightgrey, inset 0 -3px 0px lightgrey;

  &:hover {
    color: $state-info;
  }

  &::after {
    top: 0;
    left: 0;
  }

  &:hover::before {
    border-top-color: $state-info;
    border-right-color: $state-info;
  }

  &:hover::after {
    border-bottom-color: $state-info;
    border-left-color: $state-info;
    transition: width 0.25s ease-out 0.25s;
  }
}

.rcw-links-message-success {
  &:hover {
    box-shadow: inset 3px 0px 0px $brand-cyan, inset -3px 0px 0px $brand-cyan,
      inset 0 3px 0px $brand-cyan, inset 0 -3px 0px $brand-cyan !important;
  }
}

.icon-event-message-success {
  color: $brand-cyan;
}

.rcw-response.event-message-success {
  box-shadow: inset 3px 0px 0px $brand-cyan, inset -3px 0px 0px lightgrey,
    inset 0 3px 0px lightgrey, inset 0 -3px 0px lightgrey;

  &:hover {
    color: $brand-cyan;
  }

  &::after {
    top: 0;
    left: 0;
  }

  &:hover::before {
    border-top-color: $brand-cyan;
    border-right-color: $brand-cyan;
  }

  &:hover::after {
    border-bottom-color: $brand-cyan;
    border-left-color: $brand-cyan;
    transition: width 0.25s ease-out 0.25s;
  }
}

.rcw-links-message-warning {
  &:hover {
    box-shadow: inset 3px 0px 0px $state-warning,
      inset -3px 0px 0px $state-warning, inset 0 3px 0px $state-warning,
      inset 0 -3px 0px $state-warning !important;
  }
}

.icon-event-message-warning {
  color: $state-warning;
}

.rcw-response.event-message-warning {
  box-shadow: inset 3px 0px 0px $state-warning, inset -3px 0px 0px lightgrey,
    inset 0 3px 0px lightgrey, inset 0 -3px 0px lightgrey;

  &:hover {
    color: $state-warning;
  }

  &::after {
    top: 0;
    left: 0;
  }

  &:hover::before {
    border-top-color: $state-warning;
    border-right-color: $state-warning;
  }

  &:hover::after {
    border-bottom-color: $state-warning;
    border-left-color: $state-warning;
    transition: width 0.25s ease-out 0.25s;
  }
}

.rcw-links-message-error {
  &:hover {
    box-shadow: inset 3px 0px 0px $state-error, inset -3px 0px 0px $state-error,
      inset 0 3px 0px $state-error, inset 0 -3px 0px $state-error !important;
  }
}

.icon-event-message-error {
  color: $state-error;
}

.rcw-response.event-message-error {
  box-shadow: inset 3px 0px 0px $state-error, inset -3px 0px 0px lightgrey,
    inset 0 3px 0px lightgrey, inset 0 -3px 0px lightgrey;

  &:hover {
    color: $state-error;
  }

  &::after {
    top: 0;
    left: 0;
  }

  &:hover::before {
    border-top-color: $state-error;
    border-right-color: $state-error;
  }

  &:hover::after {
    border-bottom-color: $state-error;
    border-left-color: $state-error;
    transition: width 0.25s ease-out 0.25s;
  }
}
// .rcw-title::before {
//   content: "Notification System ";
//   font-family: "Segoe UI", Tahoma, Roboto, Helvetica, Arial, sans-serif;
//   padding: 15px 0px !important;
//   font-size: 18px !important;
// }

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.rcw-widget-container {
  position: relative !important;
  margin: 0px !important;
}

.rcw-widget-container.rcw-opened {
  margin-top: 32.5px !important;
}

.rcw-opened {
  margin: 0 0px -550px 0 !important;
}

.rcw-launcher {
  height: 50px !important;
  width: 50px !important;
  box-shadow: none !important;
}

.rubberBand > .rcw-widget-container {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
  animation-iteration-count: infinite;
  animation-duration: 1.2s;
  animation-fill-mode: both;
}

.rcw-date-message-style {
  text-align: right;
  font-size: 10px;
  padding-top: 5px;
}

.rcw-icon-message-style > .icon {
  font-size: 25px;
}
.rcw-text-message-style {
  font-size: 14px;
}

.chat-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 0.25fr 0.5fr 0.5fr;
  grid-template-areas: "chat-icon chat-date" "chat-text chat-text" "chat-text chat-text";
}

.chat-icon {
  grid-area: chat-icon;
}

.chat-date {
  grid-area: chat-date;
}

.chat-text {
  grid-area: chat-text;
}

div.chat-text > a {
  cursor: pointer;
  display: block;
  text-align: center;
}

@media all and (-ms-high-contrast: none) {
  .chat-grid-container {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr;
    -ms-grid-rows: 1fr 1fr 1fr;
  }

  .chat-icon {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }

  .chat-date {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
  }

  .chat-text {
    -ms-grid-row: 2;
    -ms-grid-row-span: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
  }
}
