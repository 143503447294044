// Open Position List Component

.balancesheets {
  &__list {
    margin: 0;
  }

  &__item {
    display: flex;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid $gray-lighter;
  }
}

.balancesheet {
  line-height: 1.5rem;
  &__type {
    flex: none;
    width: 3rem;
    font-size: 0.75rem;
  }

  &__position {
    flex: none;
    width: 18rem;
    font-size: 0.75rem;
  }

  &__amount {
    flex: 1;
    color: $gray-dark;
    font-weight: 400;
    font-size: 0.9rem;
  }
}
