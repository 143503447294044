//Social Sharing Links
.sharing {
  margin-top: 1rem;

  &__link {
    display: inline-block;
    width: 32px;
    height: 32px;
    line-height: 30px;
    text-decoration: none;
    color: #fff;
    border-radius: 50%;
    // padding: 5px;
    padding: 6px;
    // margin-right: .4rem;
    margin-right: -0.4rem;

    // margin-top: .3rem;
    margin-top: 0rem;
    transition: background-color 0.2s linear,
      transform 0.2s $animation-curve-default;
    text-align: center;
    transform: scale(1);
    overflow: hidden;

    transform: scale(0.7);

    @include hover-focus {
      // transform: scale(1.1);
      transform: scale(0.8);
    }

    .icon {
      width: 18px;
      height: 18px;
    }

    &--email {
      background-color: $brand-primary;

      @include hover-focus {
        background-color: darken($brand-primary, 10%);
      }
    }

    &--news {
      background-color: $newspaper-color;

      @include hover-focus {
        background-color: darken($newspaper-color, 10%);
      }
    }

    &--linkedin {
      background-color: $linkedin-color;

      @include hover-focus {
        background-color: darken($linkedin-color, 10%);
      }
    }

    &--xing {
      background-color: $xing-color;

      @include hover-focus {
        background-color: darken($xing-color, 10%);
      }
    }

    &--facebook {
      background-color: $facebook-color;

      @include hover-focus {
        background-color: darken($facebook-color, 10%);
      }
    }

    &--twitter {
      background-color: $twitter-color;

      @include hover-focus {
        background-color: darken($twitter-color, 10%);
      }
    }

    &--youtube {
      background-color: $youtube-color;

      @include hover-focus {
        background-color: darken($youtube-color, 10%);
      }
    }
  }

  &__textarea {
    textarea:focus::placeholder {
      color: transparent;
    }
    textarea:focus::-webkit-input-placeholder {
      color: transparent;
    }
    textarea:focus:-moz-placeholder {
      color: transparent;
    } /* FF 4-18 */
    textarea:focus::-moz-placeholder {
      color: transparent;
    } /* FF 19+ */
    textarea:focus:-ms-input-placeholder {
      color: transparent;
    } /* IE 10+ */
    textarea::-webkit-input-placeholder {
      color: #ccc;
    }
    textarea:-moz-placeholder {
      color: #ccc;
    } /* Firefox 18- */
    textarea::-moz-placeholder {
      color: #ccc;
    } /* Firefox 19+ */

    textarea:-ms-input-placeholder {
      color: #ccc;
    }
    textarea::placeholder {
      color: #ccc;
    }
  }
  &__textfield {
    box-shadow: none;
    color: inherit;
    padding: inherit;
    box-sizing: border-box;
    width: 100%;
    color: #4a4a4a;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    padding: 0.5em;
    font-size: 14px;
    margin-bottom: 0.75em;
    outline: none;
    resize: none;

    &__subject {
      width: 100%;
      height: 2.9em;
    }
    &__message {
      width: 100%;
      height: 10em;
    }
  }
}
