// Analysis Detail
.analysis {
  &__header {
    font-size: 1rem;
    border-bottom: 1px solid $gray-lighter;
    padding-bottom: $spacing-y;
    margin-bottom: $spacing-y;
  }
  &__logo {
    display: block;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
      max-height: 160px;
    }
  }
  &__title {
    width: 100%;
    font-size: 1em;
    font-weight: 500;
    margin: 0;
  }
  &__info {
    display: flex;
    flex-wrap: wrap;
  }
  &__address {
    @include hyphenate;
    width: 50%;
  }
  &__contact {
    @include truncate;
    width: 50%;
    padding-left: 10px;
  }
  &__print {
    padding-top: 1rem;
    margin-left: -1rem;
    padding-bottom: 1.4rem;
  }
  @include mq(md) {
    &__header {
      display: flex;
      font-size: 1.25rem;
      font-weight: 200;
    }
    &__logo {
      flex: 0 1 33.33333%;
      max-width: 33.33333%;
      padding-right: $spacing-x;
      margin: 0;
    }
    &__info {
      flex: 1 0 66.6666%;
      padding-left: 10px;
    }
    &__print {
      margin-top: -1rem;
      margin-left: 0rem;
      padding-bottom: 0rem;
    }
    &__stats {
      // make boxes equal height
      .box,
      [class^="col"] {
        display: flex;
        flex-direction: column;
      }
      .box,
      .box__body {
        flex: 1 0 auto;
        padding: 0.5rem;
        padding-bottom: 0.75rem;
      }
    }
  }
}