// Topbar Component
// containers/Topbar.jsx
.footer {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: z("topbar");
  height: 60px;
  background-color: $gray-light;
  color: white;

  @include mq(md) {
    overflow: hidden;

    .container {
      padding: 0;
    }
  }

  &__inner {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__center {
    flex: 1 0 auto;
    text-align: center;
    display: flex;
    margin: auto;
    .icon {
      height: 2.5em;
      width: 2.5em;
    }
    @include mq-down(xl) {
      text-align: -webkit-center;
    }
    &__inner {
      display: flex;
      margin: auto;
    }
    &__text {
      margin: auto;
      padding-left: 1em;
      line-height: 2.5em;
    }
  }
}
