// Searchfield Component
// components/Searchfield.jsx
.searchfield {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: z("search");
  height: $topbar-height + 2px; //account for top border in nav menu overlay
  background-color: #fff;
  color: $brand-secondary;
  box-shadow: $box-shadow-elevated;

  .search-open & {
    display: block;
  }

  .container {
    display: flex;
    align-items: center;
    height: 100%;
    padding-right: 80px;
  }

  &__label {
    display: none;
    position: absolute;
    top: 20%;
    left: 15px;

    .icon {
      width: 1.75rem;
      height: 1.75rem;
    }
  }

  &__input {
    display: block;
    width: 100%;
    padding: 0.5rem 0;
    border: 0;
    border-bottom: 1px solid $gray;
    font-size: 1.125rem;
    font-weight: 300;
    color: $brand-secondary;
  }

  &__close {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    color: $brand-secondary;

    .icon {
      width: 40px;
      height: 40px;
    }
  }

  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: $brand-secondary;
  }

  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: $brand-secondary;
    opacity: 1;
  }

  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $brand-secondary;
    opacity: 1;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $brand-secondary;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $brand-secondary;
  }

  @include mq(md) {
    display: block;
    left: auto;
    right: auto;
    height: auto;
    max-width: 20rem;
    background-color: transparent;
    color: #fff;
    box-shadow: none;

    .container {
      display: block;
      height: auto;
      padding: 0;
      top: 10px;
      width: 25vw;
    }

    &__label {
      display: block;
    }

    &__input {
      padding: 0.5rem 0.5rem 0.5rem 3.25rem;
      border-radius: 0.25rem;
      border: 1px solid $white;
      background-color: transparent;
      color: #fff;
    }

    &__close {
      display: none;
    }

    ::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #fff;
    }

    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #fff;
      opacity: 1;
    }

    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #fff;
      opacity: 1;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #fff;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #fff;
    }
  }

  @include mq(xl) {
    max-width: 35rem;
  }
}

.searchfieldnew {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  // background-color: #fff;

  &__dropdown {
    margin: 10px 0 0;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 0, rgba(0, 0, 0, 0.1) 0px 4px 11px;
    border-radius: 5px;
    .ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      .li {
        padding: 10px 20px;
        background-color: #fff;
        border: 1px solid $gray-lighter;
        height: 40px;
        display: flex;
        align-items: center;
        color: $gray-dark;
        &:hover {
          background-color: $gray-lightest;
          cursor: pointer;
          color: $brand-primary;
        }

        &:first-child {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
        &:last-child {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
        &:not(:first-child) {
          border-top: 0;
        }
        .icon {
          margin-right: 0.5em;
          height: 1em;
        }
      }
    }
  }

  &__input {
    font-size: 1.125em;
    padding: 10px 20px;
    height: 45px;
    // border: 1px solid $gray-lighter;
    border: 1px solid #fff;
    border-radius: 5px;
    // color: $gray-dark;
    color: $brand-secondary;
    background-color: #fff;
    &:focus {
      outline: none;
    }
    @include mq(md) {
      background-color: transparent;
      color: #fff;
    }
  }

  @include mq(md) {
    // background-color: transparent;
    ::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #fff;
    }

    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #fff;
      opacity: 1;
    }

    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #fff;
      opacity: 1;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #fff;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #fff;
    }
  }
}
