.opportunitymanagementtemplate {
  font-family: 'Nunito',
  sans-serif;
  // ### topbar ###
  section>section>#maincontainer>div>div.col-lg-10 {
    padding-left: 31px;
  }
  section>section>header {
    background: $opm-blue;
    background: linear-gradient(90deg, rgba(69, 153, 255, 1) 0%, rgba(118, 221, 255, 1) 56%, rgba(69, 153, 255, 1) 100%);

    div {
      padding-left: 0px;

      div.topbar__center {
        padding-left: 15px;
      }

      div.topbar__left {
        margin-right: 25px;
      }

      div.topbar__left>a {
        min-width: 116px;
        text-decoration: none;

        .logocontaineropm {
          display: flex;
          align-items: flex-start;
          width: 295px;
        }

        .logocontaineropm-item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          svg {
            height: 30px;
            width: 235px;
            padding-top: 4px;
          }

          div.title {

            font-size: 14px;
            padding-top: 8px;
            font-weight:600;
            display: none;

            @include mq(md) {
              display: block;
            }
          }
          div.module {

            font-size: 17px;
            font-weight:700;
            padding-top: 4px;
            display: none;

            @include mq(md) {
              display: block;
            }
          }
        }
      }
    }

    div.topbar__right {
      div>div>.rcw-launcher {
        background: none !important;
      }

      div>div>.rcw-hide-sm {
        background: $opm-blue !important;
      }

      div>div>div>div.rcw-header>h4 {
        background: $opm-blue !important;
        margin-left: 0px !important;
      }

      div>div.rcw-opened>div {
        #messages>div.rcw-message>div.rcw-response {
          padding-left: 15px;
        }

      }
    }


  }
  // ### section elements ###

  .box-color-blue .box__body {
    background-color: $opm-blue;
  }
  .control input:checked~.control_indicator-Strategy {
    background: $opm-blue !important;
  }
  div[class*="loading"] {
    .spinner::before {
      border-top: 4px solid $opm-blue !important;
    }
    span[class*="loading"] {
      color:$opm-blue !important
      
      
    }
  }
  .spinner {
    &::before {
      border-top: 4px solid $opm-blue !important;
    }
  }
  .btn--blue,
  .filterbutton,
  .filterbuttonSecondary {
    background-color:$opm-blue !important;
  }
  .tab-list-active {
    color: $opm-blue;
    border-top: 2px solid $opm-blue;
  }
  .ant-btn:hover{
    border-color: $opm-blue !important;
    span {
      color:$opm-blue
    }
  }
  .ant-btn-primary:hover {
    span {
      color: white !important;
    }
    
  }

  .ant-btn-primary {
    
    border-color: $opm-blue !important;
    background-color: $opm-blue;
  }

  .statistics-qualifier .icon{
    stroke: $opm-blue
  }
  div[class*="MuiTrackComponent-track-"] {
    background-color: $opm-blue;
  }
  
  div[class*="MuiHandleComponent-root"] {
    background-color: $opm-blue;
  }
  .nav {
    
    @include mq-down(lg) {
    background-color: $opm-blue;
    }
  }
  
  .logoopm {
    svg:hover {
      transform: scale(1.1);
      transform-origin: center
    }
    margin-right: 10px;
    background: rgb(250,190,58);
    background: linear-gradient(90deg, rgba(250,190,58,1) 0%, rgba(252,222,70,1) 100%);
    padding: 0px 11px;
    border-radius: 0px 4px 4px 0px;
    box-shadow: 0px 1px 3px rgba(45, 64, 89, 0.16);
  }
  
  .nav__link:hover,
  .nav__item--active,
  .company__industryBadge__icon,
  .company__businessActivityBadge__icon,
  .article_news_social__category,
  .message__category,
  .faq__center
  {
    color:$opm-blue;
    svg {
      fill: $opm-blue !important;
    }
  }
  
  .icon--dollar,
  .icon--people {
    fill: $opm-blue !important;
  }
  .icon--save {
    &--active {
      stroke: $opm-blue;
      color: $opm-blue;
    }
    &:hover {
      stroke: $opm-blue;
      color: $opm-blue;
    }
  }
  .spinner_salesbeat::before {
    border-top: 4px solid $opm-blue !important;
  }
  .spinner_salesbeat {
    .logo-spinner {fill: $opm-blue}
  }
  .timeDirector {
    
    background-color: $opm-blue !important;
    border-color: $opm-blue !important;
  }

  .timeDirectorEnd {
    
    background-color: darken($opm-blue, 20),
     !important;
    border-color: darken($opm-blue, 20) !important;
  }

  .counter-invite,
  span.circle {
    background-color: $opm-blue
  }
  .box-invite .box .box__body {
    border: 2px solid $opm-blue;
  }
  .messagecategory {
    &__item {
      &__text {
        &:hover {
          color: $opm-blue;
        }
      }
    }
  }

  .nav__link {
    &--addcompany {
      color: $opm-blue;
    }

  }
  .nav {
    &__link {
      @include hover-focus {
        color: $opm-blue;
      }
    }
  }
  .btn--primary,
  .btn--gray--coral:hover
    {
    background-color: #FC9346;
  }
  .btn--secondary,
  em {
    color: #FC9346;
  }
  .icondiv, .icondiv:hover {
    background-color: $opm-blue;
    svg:hover {
      transform: scale(1.1);
      transform-origin: center;
    }
  }

}
