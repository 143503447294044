.responsive-table {
  width: 100%;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  border-spacing: 0;
}

.responsive-table thead {
  visibility: hidden;
  background: #fafafa;
}

@media only screen and (min-width: 1200px) {
  .responsive-table thead {
    visibility: visible;
  }
}

.responsive-table tr {
  border: 1px solid #ddd;
  border-bottom: 2px solid #ddd;
  padding: 5px;
  margin-bottom: 10px;
  display: block;
  width: calc(100vw - 26.5vw);

}

@media only screen and (min-width: 520px) {
  .responsive-table tr {
    width: calc(100vw - 25.5vw);
  }
}

@media only screen and (min-width: 560px) {
  .responsive-table tr {
    width: calc(100vw - 22.5vw);
  }
}

@media only screen and (min-width: 600px) {
  .responsive-table tr {
    width: calc(100vw - 21.5vw);
  }
}

@media only screen and (min-width: 640px) {
  .responsive-table tr {
    width: calc(100vw - 20.5vw);
  }
}

@media only screen and (min-width: 710px) {
  .responsive-table tr {
    width: 100% !important;
  }
}

@media only screen and (min-width: 1200px) {
  .responsive-table tr {
    display: table-row;
    border-bottom-width: 1px;
    margin-bottom: 0;
  }

  .responsive-table tr:nth-child(even) {
    background: #fafafa;
  }
}

.responsive-table th,
.responsive-table td {
  padding: 10px;
  text-align: left;
}

.responsive-table th {
  text-transform: uppercase;
  font-size: 11px;
}

.responsive-table td {
  display: block;
  text-align: right;
  font-size: 13px;
  border-bottom: 1px dotted #ddd;
}

.responsive-table td:last-child {
  border-bottom: none;
}

@media only screen and (min-width: 1200px) {
  .responsive-table td {
    display: table-cell;
    text-align: left;
    font-size: 14px;
    border-bottom: none;
  }
}

.responsive-table td:before {
  content: attr(data-label);
  float: left;
  text-transform: uppercase;
  font-weight: bold;
}

@media only screen and (min-width: 1200px) {
  .responsive-table td:before {
    content: "";
    display: none;
  }
}
.showentriescontainer {
  margin-top: 10px;
}
.span-add-template {
  margin-right: 10px;

  .icon {
    width: 19px;
    height: 19px;
  }
}