//Basic Layout

body {
  overflow-y: scroll;

  @include mq(md) {
    overflow: hidden;
  }

  &.modal-open {
    overflow: hidden;
  }

  @include mq-down(lg) {
    &.menu-open {
      overflow: hidden;
    }
  }
}

.app {
  position: relative;
  min-height: 100vh;
  padding-top: $topbar-height;

  @include mq(md) {
    >.container {
      padding: 0;
    }
  }
}

.main {
  padding: $spacing-y 0;
}