// Social Information Container
.social__container {
  display: none;

  .box {
    &:not(.box--expanded) .box__content {
      display: none;
    }

    // Show expand button
    &__togglebtn {
      display: block;
      padding: 0 1.375rem;
    }

    &.box--expanded {
      .box__content {
        margin-top: 2.1rem;
      }

      // Hide text and rotate expand button
      .box__togglebtn {
        bottom: initial;
        padding-top: 1.375rem;
        text-indent: 150%;

        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  &.col-md-6 {
    .box__content {
      .social:not(.social__youtube) {
        @include mq(md) {
          width: 100%;
        }
      }
    }
  }

  &.col-md-12 {
    .box__content {
      display: flex;
      flex-wrap: wrap;

      .social:not(.social__youtube) {
        @include mq(md) {
          width: 50%;
        }
      }
    }
  }


  @include mq(md) {
    display: block;
  }
}

.social {
  max-height: 400px;

  &__youtube {
    width: 100%;

    iframe {
      border: 0;
    }
  }
}
