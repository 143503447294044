// Overwrite Slack Feedback stylings
.slackfeedback {
  @include mq-down(md) {
    display: none;
  }

  &__button {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      width: 8rem;
    }
  }
}

.dsJzHk {
  background: $brand-primary !important;
  right: 1.5rem !important;
  bottom: -0.5rem !important;
  padding: 0.75em 1.25em !important;
  font-size: 0.875rem !important;
  border-radius: 0.25rem !important;
  font-family: Segoe UI, Tahoma, Roboto, Helvetica, Arial, sans-serif !important;
}

.dPBOQj {
  background: #00c49f !important;
}

.gvUJCI > li.selected {
  border-color: $brand-secondary !important;
  background: $brand-secondary !important;
}

.dOGzrh {
  color: $brand-secondary !important;
  font-size: 12px;
}

.ecqgOo {
  background: $brand-secondary !important;
}
