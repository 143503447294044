.faq {
  margin-bottom: 3em;
  &__center {
    margin-top: 2em;
    text-align: center;
    .icon {
      height: 5em;
      width: 5em;
      cursor: default;
      color: $brand-secondary;
    }
  }
  &__headline {
    font-size: 2em;
    font-weight: 600;
  }
  &__text {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    &__email {
      color: #4a4a4a;
    }
    @include mq-down(md) {
      width: 90%;
    }
  }

  &__box {
    margin-top: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__list {
    list-style: none;
    max-width: 100%;
    margin-bottom: -1px;
  }

  &__item {
    position: relative;
    font-size: 1rem;
    border-bottom: 1px solid $gray-lighter;
    .icon {
      height: 1em;
      width: 1em;
      margin-right: 0.5em;
      margin-left: 0.5em;
      margin-bottom: -0.15em;
    }

    &__title {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      padding-left: 1rem;
      margin-top: 0rem;
      margin-bottom: 0rem;
      font-weight: 600;
      @include hover {
        background-color: $gray-lightest;
      }

      cursor: pointer;
      transform: translate3d(0, 0, 0);
      &::after {
        content: "+";
        font-size: 24px;
        // color: #000;
        margin-top: -0.5rem;
        transition: transform 0.5s $animation-curve-default;
        position: absolute;
        right: 30px;
      }

      &.is-expanded {
        @include hover {
          background-color: #fff;
        }

        &::after {
          content: "-";
          transform: rotate(-360deg);
        }
      }
    }

    &__content {
      height: 100%;
      overflow: hidden;
      max-height: 0;
      border-top: 0;
      padding-left: 0.9375rem;
      padding-bottom: 0rem;

      &.is-expanded {
        max-height: 100%;
        overflow: auto;
        background: $gray-lightest;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }
  }
}
