// Modal Dialog Component

.modal {
  display: none;
  position: relative;
  z-index: z("modal");

  .modal-open & {
    display: block;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($gray, 0.5);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 1rem;

    @include mq(md) {
      padding: 3rem;
    }
  }

  &__body {
    background-color: #fff;
    padding: $box-padding;
    margin: 0 auto;
    max-width: 80%;
    position: relative;
    box-shadow: $box-shadow-elevated;

    &--small {
      max-width: 460px;
    }
  }

  &__header {
    padding-bottom: 1rem;
    margin-bottom: $spacing-y;
    padding-right: 60px;
    border-bottom: 1px solid $gray-lighter;
  }

  &__title {
    margin: 0;
  }

  &__close {
    border: 0;
    padding: 0;
    background: none;
    display: block;
    width: 70px;
    height: 70px;
    position: absolute;
    right: 0;
    top: 0;

    .icon {
      width: 30px;
      height: 30px;
    }
  }

  &__content {
    .grid-container {
      display: grid;
      width: 100%;
      max-width: 50%;

      margin: 0 auto;
      grid-template-columns: repeat(2, 50%);
      grid-gap: 10px;
    }

    .grid-container div {
      height: 30vh;
      color: white;
      font-size: 2vh;
      padding: 20px;
      text-align: center;
    }

    .grid-container div h1 {
      font-size: 2.4vh;
    }

    .flex-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .icon--tasklist,
      .icon--beaker,
      .icon--search,
      .icon--squirrel,
      .icon--rocket,
      .icon--linkedin {
        min-width: 48px;
        min-height: 48px;
        margin-bottom: 40px;
        width: 50%;
        height: 50%;
      }
    }

    .icondiv {
      background: $brand-primary;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      body {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        background: #f0f0f0;
      }

      .box {
        position: relative;
        max-width: 600px;
        width: 90%;
        height: 400px;
        background: #fff;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
      }

      .ribbon {
        width: 110px;
        height: 110px;
        overflow: hidden;
        position: absolute;
      }

      .ribbon::before,
      .ribbon::after {
        position: absolute;
        z-index: -1;
        content: "";
        display: block;
        border: 5px solid #2980b9;
      }

      .ribbon span {
        position: absolute;
        display: block;
        width: 170px;
        padding: 10px 0;
        background-color: #3498db;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
        border: 0.5px solid white;
        color: #fff;
        font-size: 13px;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
        text-transform: uppercase;
        text-align: center;
        padding-left: 30px;
      }

      .ribbon-top-left {
        align-self: flex-start;
        left: 0;
        top: 0;
        position: absolute;
      }

      .ribbon-top-left::before,
      .ribbon-top-left::after {
        border-top-color: transparent;
        border-left-color: transparent;
      }

      .ribbon-top-left::before {
        top: -2px;
        right: 26px;
      }

      .ribbon-top-left::after {
        bottom: 26px;
        left: -0.1vw;
      }

      .ribbon-top-left span {
        right: 6px;
        top: 20px;
        transform: rotate(-45deg);
      }
    }

    .icondiv:hover {
      background-color: darken($brand-primary, 15%);
    }

    .form_cancelbutton {
      margin-top: 10px;
      border-top: 1px solid #d8d8d8;
    }

    @include mq-down(lg) {

      h1,
      i {
        display: none;
      }

      .grid-container {
        max-width: 100%;
      }
    }

    @include mq-down(md) {
      .icondiv {
        .ribbon span {
          padding: 4.5px 0;
          font-size: 12px;
          padding-left: 19px;
        }
      }
    }
  }
}

.modal-dropdown {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.flex-row-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.flex-row-container>.flex-row-item {
  flex: 1 1 30%;
  text-align: center;
}

.person-switch>.react-switch-bg {
  background: green;
}