// Button Component

.btn {
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 0.75em 1em 0.5625em;
  border: 2px solid transparent;
  border-radius: 0.25rem;
  background-color: transparent;
  transition: all 0.15s linear;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  // Share hover and focus styles
  @include hover-focus {
    text-decoration: none;
  }

  & + & {
    margin-left: 1em;
  }
}

.btn--add {
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 400;
  // line-height: 1.25;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 0.75em 1em 0.5625em;
  border: 2px solid transparent;
  border-radius: 0.25rem;
  background-color: transparent;
  transition: all 0.15s linear;
  justify-content: center;
  align-items: center;
  height: 0;
  padding-bottom: 100%;
  width: 100%;
  min-height: 250px;

  @include mq-down(lg) {
    padding-bottom: 25%;
    min-height: 50px;
    
  }
  @include mq-down(md) {
    padding-bottom: 48%;
    min-height: 50px;
  }

  // Share hover and focus styles
  @include hover-focus {
    text-decoration: none;
  }

  & + & {
    margin-left: 1em;
  }

  .icon {
    width: 6em;
    height: 6em;
  }

  .center {
    display: block;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

button.btn[disabled],
a.btn[disabled] {
  cursor: default;
  pointer-events: none;
  transition: none;
}

// Block Modifier for full-width
// chain with self to override .btn + .btn
.btn.btn--block {
  display: block;
  width: 100%;

  & + & {
    margin: 1rem 0 0 0;
  }
}

// Primary CTA Buttons
.btn--primary {
  color: #fff;
  background-color: $brand-primary;

  &.btn[disabled] {
    background-color: darken($gray-lightest, 5%);
    color: $gray-light;
  }

  @include hover-focus {
    background-color: darken($brand-primary, 15%);
  }
}

// Secondary Action Buttons
.btn--secondary {
  color: $brand-primary;
  background-color: transparent;
  border: 2px solid currentColor;

  &.btn[disabled] {
    color: $gray-light;
    border-color: $gray-lighter;
  }

  @include hover-focus {
    color: darken($brand-primary, 15%);
  }
}

.btn--blue {
  color: #fff;
  background-color: $brand-secondary;

  &.btn[disabled] {
    background-color: darken($gray-lightest, 5%);
    color: $gray-light;
  }

  @include hover-focus {
    background-color: darken($brand-secondary, 15%);
  }
}

.btn--lightblue {
  color: #fff;
  // background-color: $gray-lighter;
  background-color: $brand-blue-lightest;

  &.btn[disabled] {
    background-color: darken($gray-lightest, 5%);
    color: $gray-light;
  }

  @include hover-focus {
    // background-color: darken($brand-secondary, 15%);
    background-color: $brand-secondary;
  }
}

.btn--gray--coral {
  color: #fff;
  background-color: $gray-light;

  &.btn[disabled] {
    background-color: darken($gray-lightest, 5%);
    color: $gray-light;
  }

  @include hover-focus {
    // background-color: darken($brand-secondary, 15%);
    background-color: $brand-primary;
  }
}

.btn--yes {
  color: #fff;
  background-color: $yes-button;

  &.btn[disabled] {
    background-color: darken($gray-lightest, 5%);
    color: $gray-light;
  }

  @include hover-focus {
    background-color: darken($yes-button, 15%);
  }
}

.btn--no {
  color: #fff;
  background-color: $no-button;

  &.btn[disabled] {
    background-color: darken($gray-lightest, 5%);
    color: $gray-light;
  }

  @include hover-focus {
    background-color: darken($no-button, 15%);
  }
}