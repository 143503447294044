.textareamessage {
  margin-top: 10px !important;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: 100%;
  box-sizing: inherit;
  margin: 0;
  align-items: center;
  border: 1px solid transparent;
  justify-content: flex-start;
  line-height: 1.5;
  position: relative;
  vertical-align: top;
  background-color: #fff;
  border-color: #dbdbdb;
  box-shadow: inset 0 .0625em .125em rgba(10, 10, 10, .05);
  width: 100%;
  display: block;
  max-width: 100%;
  min-width: 100%;
  padding: calc(.75em - 1px);
  resize: vertical;
  border-radius: 2px;
  max-height: 22em;
  min-height: 8em;
  margin-top: 0px;
  margin-bottom: 0px;
  height: 50vh;
}