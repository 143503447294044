// News Page (Home)

.news_social {
  &__list {
    list-style-type: none;
    margin: 0;
  }

  &__item {
    // margin-bottom: $spacing-y;
    border-bottom: 1px solid $gray-lighter;

  }

  &__sorting {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    margin-bottom: 1rem;

    &__label {
      padding-right: .5rem;
      text-transform: uppercase;
      font-size: .875rem;
    }

    &__option {
      border: 0;
      padding: .5em 1em;
      background: transparent;
      font-weight: normal;

      &:hover {
        color: $brand-primary;
      }

      &:not(:last-child) {
        border-right: 1px solid $gray-lighter;
      }

      &--active {
        font-weight: 500;

        &:hover {
          color: $body-color;
        }
      }
    }

    @include mq(lg) {
      font-size: .875rem;

      &__option {
        padding: .25em .5em;
      }
    }
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;

    &__message {
      padding-left: 1rem;
      text-transform: uppercase;
      font-size: .875rem;
      font-weight: 500;
      color: $brand-secondary;
    }
  }
  @include mq(md) {
  &__stats {
    width: 100%;

    // make boxes equal height
    .box,
    [class^="col"] {
      display: flex;
      flex-direction: column;
    }

    .box,
    .box__body {
      flex: 1 0 auto;
      padding: 0.5rem;
    }
  }
  }
}