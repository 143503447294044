// Dashboard Detail
.dashboard {
  &__header {
    font-size: 1rem;
    border-bottom: 1px solid $gray-lighter;
    padding-bottom: $spacing-y;
    margin-bottom: $spacing-y;
  }

  &__greeting {
    padding-left: 0.5em;
    padding-bottom: 2em;
    padding-top: 3.5em;

    @include mq-down(md) {
      padding-left: 0.5em;
      padding-bottom: 1em;
      padding-top: 1.5em;
    }

    &__headline {
      font-weight: 600;
    }

    &__subline {
      font-size: 1.125em;
    }

    &__picture {
      padding-left: 8em;
      padding-right: 5em;
      margin-top: -7em;
      margin-left: 6em;
      margin-bottom: -5em;

      @include mq-down(xl) {
        padding-left: 2em;
        padding-right: 1em;
        margin-top: -5em;
        margin-left: 4em;
        margin-bottom: 1em;
      }

      @include mq-down(md) {
        display: none;
      }

      &__small {
        @include mq(xl) {
          display: none;
        }
      }

      &__xl {
        @include mq-down(xl) {
          display: none;
        }

        @media screen and (-ms-high-contrast: active),
        (-ms-high-contrast: none) {
          height: 35em;
        }
      }
    }
  }

  &__logo {
    display: block;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 160px;
    }
  }

  &__title {
    width: 100%;
    font-size: 1em;
    font-weight: 500;
    margin: 0;
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
  }

  &__address {
    @include hyphenate;
    width: 50%;
  }

  &__contact {
    @include truncate;
    width: 50%;
    padding-left: 10px;
  }

  &__btnwrapper {

    margin-top: 0.5em;
    display: flex;
    flex-flow: wrap;
  }

  &__selectwrapper {
    margin-left: 0.5em;
    padding-right: 0em !important;
    margin-top: 0.6em;
    z-index: 50;
  }

  &__filtercategories {
    font-size: 1rem;
    text-align: right;
    padding-top: 1.5rem;

    @include mq-down(md) {
      text-align: inherit;
      margin-bottom: -0.75rem;
    }
  }

  &__filteroptions {
    padding-top: 0.5rem;
    white-space: nowrap;
    display: block;
    padding-bottom: 0.5rem;
    width: 100%;
  }

  &__filterBg {
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s $animation-curve-default;
    margin-left: 1.5rem;
    margin-top: -0.5rem;
    padding-right: 0.5em;
    background: $gray-lightest;
    z-index: z("content-overlay");

    &.is-expanded {
      margin-top: -0.5rem;
      max-height: 30rem;
      overflow: hidden;
      transition: max-height 0.5s $animation-curve-default;
      background: $gray-lightest;
      padding-bottom: 1rem;

      @include mq-down(md) {
        max-height: 55rem;
        margin-left: 0rem;
      }
    }
  }

  &__total_lead {
    .box {
      font-size: 65px;

      .box__body {
        display: flex;
        align-items: center;
        justify-content: center;

        .box__content {
          height: auto !important;
          width: auto !important;
        }
      }
    }
  }

  &__centertitle {
    .box {
      margin: 0;
      height: 100%;

      .box__body {
        display: flex;
        align-items: center;
        justify-content: center;

        li {
          list-style-type: none;

          a {
            padding: 0.25em 0;
          }
        }

        .box__content {
          height: 100%;
          width: 100%;

          .nav__item {
            .nav__link {
              font-size: 13px;
              color: white;
            }

            .nav__link:hover {
              color: black;
            }
          }
        }
      }
    }

    &__box_news {
      .div {
        height: 100%;
      }
    }
  }

  @include mq(md) {
    &__header {
      display: flex;
      font-size: 1.25rem;
      font-weight: 200;
    }

    &__logo {
      flex: 0 1 33.33333%;
      max-width: 33.33333%;
      padding-right: $spacing-x;
      margin: 0;
    }

    &__info {
      flex: 1 0 66.6666%;
      padding-left: 10px;
    }

    &__stats {

      // make boxes equal height
      .box,
      [class^="col"] {
        display: flex;
        flex-direction: column;
      }

      .box,
      .box__body {
        flex: 1 0 auto;
        padding: 0.5rem;
      }
    }
  }
}

.box-color-red {
  .box {
    &__body {
      background-color: $brand-coral;
    }
  }
}

.box-color-blue {
  .box {
    &__body {
      background-color: $brand-blue;
    }
  }
}

.box-color-green {
  .box {
    &__body {
      background-color: $brand-cyan;
    }
  }
}

.news_label>div.box {
  padding-top: 0px !important;
}

g.recharts-layer.recharts-label-list>text>tspan {
  font-size: 12px;
}

div.dashboard__stats.dashboard__box_news>section>div:nth-child(1)>div>div>div {
  display:flex;
  justify-content: center;
  align-items: center;
}

@include mq(md) {
  .dashboard_qualifier_widget {
    min-height: 175px !important;
  }
}