// Settings Detail
.settings {
  &__header {
    font-size: 1rem;
    border-bottom: 1px solid $gray-lighter;
    padding-bottom: $spacing-y;
    margin-bottom: $spacing-y;
  }

  &__logo {
    display: block;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 160px;
    }
  }

  &__title {
    width: 100%;
    font-size: 1em;
    font-weight: 500;
    margin: 0;
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
  }

  &__address {
    @include hyphenate;
    width: 50%;
  }

  &__contact {
    @include truncate;
    width: 50%;
    padding-left: 10px;
  }

  &__information {
    font-size: 0.9rem;
    color: $gray-light;
    padding-top: .75rem;
    padding-bottom: .2rem;
  }

  @include mq(md) {
    &__header {
      display: flex;
      font-size: 1.25rem;
      font-weight: 200;
    }

    &__logo {
      flex: 0 1 33.33333%;
      max-width: 33.33333%;
      padding-right: $spacing-x;
      margin: 0;
    }

    &__info {
      flex: 1 0 66.6666%;
      padding-left: 10px;
    }

    &__stats {

      // make boxes equal height
      .box,
      [class^="col"] {
        display: flex;
        flex-direction: column;
      }

      .box,
      .box__body {
        flex: 1 0 auto;
        padding: 0.5rem;
      }
    }
  }
}

.add-new-user {
  margin-top: $spacing-y;

  p {
    text-align: center;
    align-self: center;
  }
}

@include mq(md) {
  .add-new-user {
    align-items: center;
    text-align: right;
    padding-top: 1.5rem;

    p {
      margin: 0;
    }
  }
}

.token {

  &__box {
    border: 1px solid $gray-lighter;
    width: 30em;
    margin-left: 1em;

  }

  &__text {
    display: inline-block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 2;
    margin-left: 1em;
    // border: none;
    text-align: center;
    // text-transform: uppercase;
    white-space: nowrap;
    vertical-align: middle;
    background-color: transparent;
    display: inline-flex;
    align-items: center;
  }

  &__buttons {
    display: inline-flex;
    float: right;
  }

  &__btn-box {
    width: 2em;
    background-color: #f7f7f7;
    border-left: 1px solid $gray-lighter;
  }

  &__btn {
    display: inline-block;
    border: none;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    vertical-align: middle;
    background-color: transparent;
    display: inline-flex;
    align-items: center;
    height: 2em;

    .icon {
      width: 1.25em;
      height: 1.25em;
      // margin-bottom: -2px !important;
      margin-right: 0.25em;
      background-color: $gray-lightest;
      margin-top: 4px;
    }
  }
}