// Open Position List Component

.managers {
  &__list {
    margin: 0;
  }

  &__item {
    display: flex;
    margin-bottom: 0.5rem;
  }
}

.manager {
  line-height: 1.5rem;
  &__icon {
    flex: none;
    width: 2.5rem;
    font-size: 0.9rem;
  }

  &__position {
    flex: none;
    width: 7rem;
    font-size: 0.85rem;
  }

  &__name {
    flex: 1;
    color: $gray-dark;
    font-weight: 400;
  }
}
