// Icon Component
.icon {
  fill: currentColor;
  display: inline-block;
  height: 0.9em;
  width: 0.9em;

  &--search {
    margin-bottom: 3px; //fix misalignement
  }

  &--management {
    height: 2em;
    width: 2em;
  }

  &--shares {
    height: 2em;
    width: 2em;
  }

  &--shareholders {
    height: 2em;
    width: 2em;
  }
  &--rocket {
    height: 1.1em;
    width: 1.5em;
    margin-bottom: -0.1em !important;
  }

  &--briefcase {
    width: 1.5em;
  }

  &--telescope {
    height: 1.1em;
    width: 1.5em;
    margin-bottom: -0.1em !important;
  }

  &--bookmark {
    width: 1.5em;
    height: 1.15em;
    margin-bottom: -0.15em !important;
  }

  &--beakerlist {
    width: 1.5em;
    height: 1.15em;
    margin-bottom: -0.15em !important;
  }

  &--info {
    margin-bottom: -0.2em !important;
    cursor: pointer;
  }

  &--filter {
    height: 0.75em;
    width: 0.75em;
  }

  &--youtube {
    width: 2em;
    height: 1.2em;
    margin-bottom: -2px !important;
  }

  &--lockclosed {
    margin-right: 5px;
    margin-bottom: -0.2px !important;
  }

  &--dollar,
  &--people {
    fill:#005fa5
  }

  @include mq(md) {
    margin-bottom: 1px;
  }
}

// Menu Burger Icon
.menuicon {
  display: block;
  width: 32px;
  height: 20px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: pointer;
  margin: 0 auto;

  &__bar {
    display: block;
    position: absolute;
    left: 0;
    height: 3px;
    width: 100%;
    background-color: currentColor;
    border-radius: 3px;
    transform: rotate(0deg);
    transition: transform 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 50%;
    }

    &:nth-child(4) {
      top: 100%;
    }

    // Active State: Transform to "X"
    .menu-open & {

      &:nth-child(1),
      &:nth-child(4) {
        width: 0;
        top: 50%;
        left: 50%;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }
    }
  }
}

// Responsive Brand Logo
.logo {
  display: block;
  fill: currentColor;
  margin-left: -4.4rem;

  &--default {
    display: none;
  }

  @include mq(md) {
    &--short {
      display: block;
    }

    &--default {
      display: block;
    }
  }
}

// Brand Logo at inital spinner
.logo-spinner {
  display: block;
  fill: $brand-blue;
  margin-top: -35px;
  margin-left: -46px;
}

.logoksv {
  display: block;
  fill: currentColor;
  margin-left: -4.4rem;

  &--st0 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: #e50058;
  }

  &--st1 {
    fill: #e50058;
  }
}

.dashpic {
  display: block;
  fill: currentColor;

  &--cls-1 {
    isolation: isolate;
  }

  &--cls-14,
  &--cls-2 {
    fill: #f2f4f6;
  }

  &--cls-2 {
    mix-blend-mode: multiply;
  }

  &--cls-3 {
    fill: #fff;
  }

  &--cls-4 {
    fill: #dce2ec;
  }

  &--cls-5 {
    fill: #005fa5;
  }

  &--cls-6 {
    fill: #ff6559;
  }

  &--cls-7 {
    fill: #00c49f;
  }

  &--cls-8 {
    opacity: 0.9;
  }

  &--cls-9 {
    fill: #ff7673;
  }

  &--cls-10 {
    fill: #003347;
  }

  &--cls-11 {
    fill: #340a73;
  }

  &--cls-12 {
    fill: #004772;
  }

  &--cls-13 {
    fill: #ffbf8d;
  }

  &--cls-15 {
    fill: #adb2b8;
  }

  &--cls-16 {
    fill: #ffdabb;
  }

  &--cls-17 {
    fill: #ff5450;
  }

  &--cls-18 {
    fill: #00151c;
  }

  &--cls-19 {
    fill: #e4e5ee;
  }

  &--cls-20 {
    fill: #7c1400;
  }
}