.timeline {
  font-size: 0.65rem;

  @media screen and (-ms-high-contrast: active),
  (-ms-high-contrast: none) {
    display: none;
  }

  &__box {
    display: none;

    @include mq(lg) {
      display: block;
    }

    @media screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
      display: none !important;
    }
  }
}

.timeCorporated {
  color: #ffffff !important;
  background-color: #ff6559 !important;
  border-color: #ff6559 !important;
}

.timeDirector {
  color: #ffffff !important;
  background-color: #005fa5 !important;
  border-color: #005fa5 !important;
}

.timeDirectorEnd {
  color: #ffffff !important;
  background-color: darken(#005fa5, 20),  !important;
  border-color: darken(#005fa5, 20) !important;
}

.timeSecretary {
  color: #ffffff !important;
  background-color: #00c49f !important;
  border-color: #00c49f !important;
}

.timeSecretaryEnd {
  color: #ffffff !important;
  background-color: darken(#00c49f, 20) !important;
  border-color: darken(#00c49f, 20) !important;
}

.timeLiquidator {
  color: #ffffff !important;
  background-color: $brand-coral !important;
  border-color: $brand-coral !important;
}

.timeLiquidatorEnd {
  color: #ffffff !important;
  background-color: darken($brand-coral, 20) !important;
  border-color: darken($brand-coral, 20) !important;
}


.timeboardMembers {
  color: #ffffff !important;
  background-color: $gray-dark !important;
  border-color: $gray-dark !important;
}

.timeboardMembersEnd {
  color: #ffffff !important;
  background-color: darken($gray-dark, 20) !important;
  border-color: darken($gray-dark, 20) !important;
}

.timeCompanyName {
  color: #ffffff !important;
  background-color: $brand-coral !important;
  border-color: $brand-coral !important;
}

.timeCompanyNameEnd {
  color: #ffffff !important;
  background-color: darken($brand-coral, 20) !important;
  border-color: darken($brand-coral, 20) !important;
}

.timeAnnouncement {
  color: #ffffff !important;
  background-color: $gray-dark !important;
  border-color: $gray-dark !important;
}

.vis-timeline {
  font-size: 0.75em !important;
}

.vis-tooltip {
  color: $gray-dark !important;
  background-color: #f7f7f7 !important;
  font-size: 1em !important;
  -moz-border-radius: 3px !important;
  -webkit-border-radius: 3px !important;
  border-radius: 3px !important;
  border: 1px solid $gray-dark !important;
  word-break: break-all !important;
}

.vis-item-content {
  padding: 2px 5px !important;
}