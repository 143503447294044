.filterbutton {
  text-align: center;
  color: #fff;
  background-color: $brand-secondary;
  margin-right: -1px; //fix overlap
  border: 1px solid $gray-lighter;
  width: 8.5em;
  font-weight: 400;
  margin-top: -1px;

  &__togglebtn {
    width: 100%;
    line-height: 1.25;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;

    &--active {
      color: $gray-dark;
      background-color: $white;
    }
  }
}

.filterbuttonSecondary {
  text-align: center;
  color: #fff;
  background-color: $brand-secondary;
  margin-right: -1px; //fix overlap
  border: 1px solid $gray-lighter;
  width: 8.5em;
  font-weight: 400;
  margin-top: -1px;

  &__togglebtn {
    width: 100%;
    line-height: 1.25;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;

    &--active {
      color: $gray-dark;
      background-color: $white;
    }
  }
}

.filterbuttonTertiary {
  text-align: center;
  color: #fff;
  background-color: $brand-secondary;
  margin-right: -1px; //fix overlap
  border: 1px solid $gray-lighter;
  width: 8.5em;
  font-weight: 400;
  margin-top: -1px;

  &__togglebtn {
    width: 100%;
    line-height: 1.25;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;

    &--active {
      color: $gray-dark;
      background-color: $white;
    }
  }
}

.filterwrapper {
  display: contents;
}
