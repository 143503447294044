@include mq(md) {
  .content {
    position: absolute !important;
    top: $topbar-height;
    bottom: 0px;
    left: 0px;
    right: 0px;
    overflow: auto;

    @media all and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
      bottom: 60px;
    }
  }

  .contentNoNavigation {
    position: absolute !important;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    overflow: auto;

    @media all and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
      bottom: 60px;
    }
  }
}