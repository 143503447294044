// News Article Component
.article_news_social {
  background-color: #fff;
  padding-right: 0.5rem;
  position: relative;
  z-index: 1;
  border-left: 7px solid transparent;
  font-size: 12px !important;
  // min-height: 140px;
  min-height: 70px;
  // border-radius: 0.25rem;

  &__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    padding-left: 0;
  }

  &__header {
    margin-top: 9px;
    margin-left: 6px;
    display: flex;
    flex-direction: column;
    // min-width: 100%;
    min-width: fit-content;
    flex: 1;
  }

  &__title {
    color: $gray-darkest;
    display: flex;
    // flex: 1;
    font-weight: 600;
    cursor: pointer;
    font-size: 0.95rem;
  }

  &__meta {
    // order: -1;
    display: flex;
    flex-direction: row;
    line-height: 1;
    position: absolute;
    bottom: -1.5rem;

    @include mq-down(md) {
      position: relative;
      bottom: 0.5rem;
    }
  }

  &__category {
    font-size: 0.75rem;
    font-weight: 600;
    color: $brand-secondary;
    position: static;
  }

  &__sentiment {
    font-size: 0.75rem;
    font-weight: 600;
    color: $gray-light;
    position: static;
  }

  &__seperator {
    font-size: 0.75rem;
    color: $gray-light;
    position: static;
    font-weight: 400;
  }

  &__date {
    font-size: 0.75rem;
    color: $gray-light;
    position: static;
  }

  &__sources {
    font-size: 0.75rem;
    // text-transform: uppercase;
    color: $gray-light;

    &__more {
      padding-left: 0.1875rem;
    }
  }

  &__social_stats {
    text-transform: uppercase;
    padding-left: 2.5rem;
    font-size: 0.75rem;
  }

  &__from {
    padding-right: 0.1875rem;
    font-size: 0.75rem;
    color: $gray-light;
    word-break: break-all;
  }

  &__company {
    font-size: 0.75rem;
    color: $gray-light;
    position: relative;
    top: 9px;
    left: 5px;
    margin: 0 26px 0 0;
    order: -1;
    margin-bottom: 1em;
    padding: 0px 130px 0 0; // space for abs positioned date

    &__name {
      @include hyphenate;
      color: $gray-light;
      font-size: 0.75rem;
    }

    &__more {
      color: $gray-light;
      padding-left: 0.1875rem;
    }
  }

  &__sharing {
    padding-left: 3.2rem;
    color: $gray-light;
    font-size: 0.75rem;

    @include mq-down(md) {
      padding-left: 0rem;
    }

    &--middle {
      display: inline-block;
      vertical-align: middle;
      line-height: normal;
    }
  }

  &__symbol {
    margin-top: 1rem;

    &__youtube {
      display: inline-block;
      text-decoration: none;
      margin-top: 0.5rem;
      text-align: center;
      overflow: hidden;

      .icon {
        width: 3em;
        height: 1.7em;
      }
    }

    &__link {
      display: inline-block;
      width: 32px;
      height: 32px;
      line-height: 30px;
      text-decoration: none;
      color: #fff;
      border-radius: 50%;
      padding: 5px;
      // margin-right: .4rem;
      margin-top: 0.2rem;
      // transition: background-color .2s linear, transform .2s $animation-curve-default;
      text-align: center;
      // transform: scale(1);
      overflow: hidden;

      .icon {
        width: 18px;
        height: 18px;
      }

      &--news {
        background-color: $newspaper-color;
      }

      &--linkedin {
        background-color: $linkedin-color;
      }

      &--facebook {
        background-color: $facebook-color;
        padding: 7px;
      }

      &--twitter {
        background-color: $twitter-color;
        padding: 6px;
      }

      &--youtube {
        background-color: $youtube-color;
        padding: 6px;
      }
      &--law {
        background-color: $brand-blue;
        color: #FFF;
        padding: 6px;
      }
    }
  }

  &__togglebtn {
    display: block;
    position: absolute;
    right: 0rem;
    // bottom: -0.75rem;
    top: 1.2rem;
    border: 0;
    padding: 1px;
    background: none;
    width: 60px;
    height: 60px;
    color: $gray-dark;
    overflow: hidden;

    @include mq(md) {
      width: 40px;
      height: 40px;
    }

    .icon {
      display: block;
      width: 100%;
      height: 100%;
      transform: rotate(0deg);
      transform-origin: 50% 50%;
    }
  }

  // Content Styles.
  &__content {
    display: none;
  }

  &__excerpt {
    font-size: 0.85rem;
    color: $gray-dark;
    padding: 0.5rem 3.2rem;

    @include mq-down(md) {
      padding: 0.5rem 0rem;
    }

    p {
      display: inline;
      line-height: 1.5rem;
      max-height: 4.5rem;
      overflow: hidden;
    }
  }

  // Unread State
  &--unread {
    border-left-color: $gray-lighter;
    border-left-width: 0.5rem;

    border-left .article_news_social__title {
      color: $body-color;
      // font-weight: 500;
    }

    &.article_news_social--trigger {
      border-left-color: $brand-primary;
    }
  }

  &--trigger {
    border-left-color: $brand-primary;
  }

  //Expanded State (Mock)
  &--expanded {
    .article_news_social__content {
      display: block;
      min-width: 100%;
    }

    .article_news_social__togglebtn .icon {
      transform: rotate(180deg);
    }

    .article_news_social__meta {
      position: initial;
    }
  }

  @media screen and (-ms-high-contrast: active),
  (-ms-high-contrast: none) {
    &__title {
      padding-bottom: 1rem;
      display: table;
    }
  }

  // Wide Layout
  @include mq(md) {

    // min-height: 75px;
    &__title {
      min-width: 250px;
      padding-right: 0px;
    }

    &__header {
      display: table;
      min-width: 70%;
      max-width: calc(100% - 150px);
    }

    &__company {
      width: 25px;
      padding-right: 0;
    }

    &--expanded .article_news_social_news_social__content {
      margin-left: 65px;
      min-width: 100%;
      padding-right: 10%;
    }

    &__meta {
      position: inherit;
      padding-bottom: 0.5rem;
      bottom: -1rem;
    }
  }

  @include mq(lg) {
    &__header {
      display: table;
      min-width: 85%;
      max-width: calc(100% - 150px);
    }
  }
}

// Pre-rendered Shadow on Hover
/*
.article_news_social {
  cursor:pointer;

  &:hover {
    &::after {
      opacity:1;
    }
  }
  &::after {
    display:block;
    content:'';
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:-1;
    opacity:0;
    box-shadow: $box-shadow-elevated;
    transition: opacity .3s $animation-curve-default;
  }
}
*/