
.events {
  &__list {
    list-style-type: none;
    margin: 0;
  }

  &__item {
    border-bottom: 1px solid $gray-lighter;

  }

  &__sorting {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    margin-bottom: 1rem;

    &__label {
      padding-right: .5rem;
      text-transform: uppercase;
      font-size: .875rem;
    }

    &__option {
      border: 0;
      padding: .5em 1em;
      background: transparent;
      font-weight: normal;

      &:hover {
        color: $brand-primary;
      }

      &:not(:last-child) {
        border-right: 1px solid $gray-lighter;
      }

      &--active {
        font-weight: 500;

        &:hover {
          color: $body-color;
        }
      }
    }

    @include mq(lg) {
      font-size: .875rem;

      &__option {
        padding: .25em .5em;
      }
    }
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;

    &__message {
      padding-left: 1rem;
      text-transform: uppercase;
      font-size: .875rem;
      font-weight: 500;
      color: $brand-secondary;
    }
  }
  @include mq(md) {
  &__stats {
    width: 100%;

    // make boxes equal height
    .box,
    [class^="col"] {
      display: flex;
      flex-direction: column;
    }

    .box,
    .box__body {
      flex: 1 0 auto;
      padding: 0.5rem;
    }
  }
  }
}


.article_events {
  background-color: #fff;
  padding-right: 0.5rem;
  position: relative;
  z-index: 1;
  border-left: 7px solid transparent;
  font-size: 12px !important;
  min-height: 70px;

  &__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    padding-left: 0;
  }

  &__header {
    margin-top: 9px;
    margin-left: 6px;
    display: flex;
    flex-direction: column;
    // min-width: 100%;
    min-width: fit-content;
    flex: 1;
  }

  &__title {
    color: $gray-darkest;
    display: flex;
    font-weight: 600;
    cursor: pointer;
    font-size: 0.95rem;
  }

  &__meta {
    display: flex;
    flex-direction: row;
    line-height: 1;
    position: absolute;
    bottom: -1.5rem;

    @include mq-down(md) {
      position: relative;
      bottom: 0.5rem;
    }
  }

  &__category {
    font-size: 0.75rem;
    font-weight: 600;
    color: $brand-secondary;
    position: static;
    display: inline-block;
    
    svg.icon {
        width: 12px;
        height: 12px;
        margin-bottom: -2px;
    }

    svg.icon--thumb-up {
        stroke: green;
    }

    svg.icon--thumb-down {
        stroke: red;
    }
  }

  &__category.neutral {
        color: grey;
        svg.icon--thumb-up {
            stroke: grey;
        }
  }
  &__category.positive {
        color: green;
        
  }
  &__category.negative {
        color: red;
        
  }

  &__sentiment {
    font-size: 0.75rem;
    font-weight: 600;
    color: $gray-light;
    position: static;
  }

  &__seperator {
    font-size: 0.75rem;
    color: $gray-light;
    position: static;
    font-weight: 400;
  }

  &__date {
    font-size: 0.75rem;
    color: $gray-light;
    position: static;
  }

  &__sources {
    font-size: 0.75rem;
    color: $gray-light;

    &__more {
      padding-left: 0.1875rem;
    }
  }

  &__social_stats {
    text-transform: uppercase;
    padding-left: 2.5rem;
    font-size: 0.75rem;
  }

  &__from {
    padding-right: 0.1875rem;
    font-size: 0.75rem;
    color: $gray-light;
    word-break: break-all;
  }

  &__company {
    font-size: 0.75rem;
    color: $gray-light;
    position: relative;
    top: 9px;
    left: 5px;
    margin: 0 26px 0 0;
    order: -1;
    margin-bottom: 1em;
    padding: 0px 130px 0 0; // space for abs positioned date

    &__name {
      @include hyphenate;
      color: $gray-light;
      font-size: 0.75rem;
    }

    &__more {
      color: $gray-light;
      padding-left: 0.1875rem;
    }
  }

  &__sharing {
    padding-left: 3.2rem;
    color: $gray-light;
    font-size: 0.75rem;

    @include mq-down(md) {
      padding-left: 0rem;
    }

    &--middle {
      display: inline-block;
      vertical-align: middle;
      line-height: normal;
    }
  }

  &__symbol {
    margin-top: 1rem;

    &__youtube {
      display: inline-block;
      text-decoration: none;
      margin-top: 0.5rem;
      text-align: center;
      overflow: hidden;

      .icon {
        width: 3em;
        height: 1.7em;
      }
    }

    &__link {
      display: inline-block;
      width: 32px;
      height: 32px;
      line-height: 30px;
      text-decoration: none;
      color: #fff;
      border-radius: 50%;
      padding: 5px;
      margin-top: 0.2rem;
      text-align: center;
      overflow: hidden;

      .icon {
        width: 18px;
        height: 18px;
      }
      
      background-color: $newspaper-color;

    &--events-news {
        svg {
            stroke: #FFF !important;
            width: 21px !important;
            height: 21px !important;
        }
    }

    &--events-website-changes {
        svg {
            
            width: 20px !important;
            height: 20px !important;
        }
        padding: 6px;
    }

    &--events-commercial-register {
        padding: 6px;
      }
    }

  }

  &__togglebtn {
    display: block;
    position: absolute;
    right: 0rem;
    top: 1.2rem;
    border: 0;
    padding: 1px;
    background: none;
    width: 40px;
    height: 40px;
    color: $gray-dark;
    overflow: hidden;

    @include mq(md) {
      width: 40px;
      height: 40px;
    }

    .icon {
      display: block;
      width: 100%;
      height: 100%;
      transform: rotate(0deg);
      transform-origin: 50% 50%;
    }
    
  }

  &__togglebtn_no_flip {
    display: block;
    position: absolute;
    right: 0rem;
    top: 1.2rem;
    border: 0;
    padding: 1px;
    background: none;
    width: 40px;
    height: 40px;
    color: $gray-dark;
    overflow: hidden;
    margin-right: 15px;

    @include mq(md) {
      width: 40px;
      height: 40px;
    }

    .icon {
      display: block;
      width: 100%;
      height: 100%;
    }
    
  }

  // Content Styles.
  &__content {
    display: none;
  }

  &__excerpt {
    font-size: 0.85rem;
    color: $gray-dark;
    padding: 0.5rem 3.2rem;

    @include mq-down(md) {
      padding: 0.5rem 0rem;
    }

    p {
      display: inline;
      line-height: 1.5rem;
      max-height: 4.5rem;
      overflow: hidden;
    }
  }

  &--type-events-news {
    border-left-color: $brand-coral;
  }
  &--type-events-bundesanzeiger {
    border-left-color: $brand-blue-light;
  }
  &--type-events-website-changes {
    border-left-color: $brand-blue-lightest;
  }
  &--type-events-commercial-register {
    border-left-color: $brand-cyan;
  }

  //Expanded State (Mock)
  &--expanded {
    .article_events__content {
      display: block;
      min-width: 100%;
    }

    .article_events__togglebtn .icon {
      transform: rotate(180deg);
    }

    .article_events__meta {
      position: initial;
    }
  }

  @media screen and (-ms-high-contrast: active),
  (-ms-high-contrast: none) {
    &__title {
      padding-bottom: 1rem;
      display: table;
    }
  }

  // Wide Layout
  @include mq(md) {

    &__title {
      min-width: 250px;
      padding-right: 0px;
    }

    &__header {
      display: table;
      min-width: 70%;
      max-width: calc(100% - 150px);
    }

    &__company {
      width: 25px;
      padding-right: 0;
    }

    &--expanded .article_events__content {
      margin-left: 5px;
      min-width: 100%;
      padding-right: 10%;
    }

    &__meta {
      position: inherit;
      padding-bottom: 0.5rem;
      bottom: -1rem;
    }
  }

  @include mq(lg) {
    &__header {
      display: table;
      min-width: 78%;
      max-width: calc(100% - 150px);
    }
  }
}