// Lead Generation
.list {
  &__list {
    list-style-type: none;
    margin: 0;
    word-break: break-all;

    li:nth-child(2n + 1) {
      background-color: $gray-lightest;
    }

    &__comp {
      margin-bottom: -33px;
    }

    &__lead {
      margin-bottom: -1.81rem;
    }

    &__leadlist {
      list-style-type: none;
      margin: 0;
      word-break: break-all;
      margin-bottom: -33px;
    }
  }

  &__item {
    border-bottom: 1px solid $gray-lighter;
    // padding: 1rem;
    padding: 0.5rem 1rem;
    position: relative;
    top: -0.5rem;
    margin: -0.5rem -1rem $spacing-y -1rem;
    font-size: 0.875rem;
    font-weight: 400;

    &--expanded {
      .list__item__content {
        padding-top: 1em;
        display: block;
      }
    }

    &__content {
      display: none;
    }
  }

  &__header {
    list-style-type: none;
    margin: 0;
    word-break: break-all;
    border-bottom: 1px solid $gray-lighter;
    padding: 1rem;
    position: relative;
    top: -0.5rem;
    margin: -0.5rem -1rem $spacing-y -1rem;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 0.75rem;
  }
}

.list__cell__companies {
  &--country {
    width: 55px;
  }

  &--name {
    overflow: hidden;
    text-overflow: ellipsis;
    color: $gray-dark;

    @include hover-focus {
      color: darken($brand-primary, 15%);
      font-weight: 500;
    }
  }

  &--number {
    width: 200px;
  }

  &--category {
    width: 200px;
  }

  &--action-edit {
    width: 150px;
  }

  &--action-delete {
    width: 100px;
  }
}
@include mq-down(xl) {
  .list__cell__companies {
    &--country {
      width: 40px;
    }

    &--name {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--number {
      width: 120px;
    }

    &--category {
      width: 120px;
    }

    &--action-edit {
      width: 120px;
    }

    &--action-delete {
      width: 90px;
    }
  }
}

@include mq-down(lg) {
  .list__cell__companies {
    &--country {
      width: 40px;
    }

    &--name {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--number {
      width: 200px;
      display: none;
    }

    &--category {
      width: 200px;
      display: none;
    }

    &--action-edit {
      width: 150px;
    }

    &--action-delete {
      width: 90px;
    }
  }
}

@include mq-down(md) {
  .list__cell__companies {
    &--country {
      width: 40px;
    }

    &--name {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--number {
      width: 200px;
      display: none;
    }

    &--category {
      width: 200px;
      display: none;
    }

    &--action-edit {
      width: 150px;
      display: none;
    }

    &--action-delete {
      width: 90px;
    }
  }
}

.list__cell__leads {
  &--type {
    width: 40px;

    .icon {
      width: 1.25em;
      height: 1.25em;
      margin-top: 0.6em;
    }
  }

  &--name {
    width: 250px;
    padding-right: 1em;

    &--item {
      color: $gray-dark;
      overflow: hidden;
      text-overflow: ellipsis;

      @include hover-focus {
        color: darken($brand-primary, 15%);
        font-weight: 500;
      }
    }
  }

  &--count {
    width: 60px;

    .icon {
      width: 1.5em;
      height: 1.5em;
      margin-top: 0.6em;
    }
  }

  &--criteria {
    padding-right: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--headLine {
    font-style: italic;
  }

  &--date {
    width: 170px;
  }

  &--action-edit {
    width: 150px;
  }

  &--action-delete {
    width: 100px;
  }

  &--template-actions {
    width: 70px;
  }

  &--contacts-photo {
    width: 50px;
  }

}

@include mq-down(md) {
  .list__cell__leads {
    &--type {
      width: 40px;

      .icon {
        width: 1.25em;
        height: 1.25em;
        margin-top: 0.6em;
      }
    }

    &--name {
      width: unset;
      padding-right: 1em;

      &--item {
        color: $gray-dark;
        overflow: hidden;
        text-overflow: ellipsis;

        @include hover-focus {
          color: darken($brand-primary, 15%);
          font-weight: 500;
        }
      }
    }

    &--count {
      width: 60px;
      display: none;
    }

    &--criteria {
      padding-right: 1em;
      overflow: hidden;
      text-overflow: ellipsis;
      display: none;
    }

    &--headLine {
      font-style: italic;
    }

    &--date {
      width: 170px;
      display: none;
    }

    &--action-edit {
      width: 150px;
      display: none;
    }

    &--action-delete {
      width: 90px;
    }
  }
}

@include mq-down(xl) {
  .list__cell__leads {
    &--criteria {
      padding-right: 1em;
      overflow: hidden;
      text-overflow: ellipsis;
      display: none;
    }
  }
}

.list__cell__leadlist {
  &__pointer {
    cursor: pointer;
  }

  &--country {
    width: 55px;
  }

  &--name {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  &--region {
    width: 200px;
  }

  &--website {
    width: 200px;
  }

  &--email {
    text-align: center;
    text-align: -webkit-center;
    width: 65px;

    &--active {
      color: $yes-button;
    }

    &--no {
      color: $gray-light;
    }

    .icon {
      width: 1.5em;
      height: 1.5em;
      margin-top: 0.6em;

    }
  }

  &--phone {
    text-align: center;
    text-align: -webkit-center;
    width: 50px;


    &--active {
      color: $yes-button;
    }

    &--no {
      color: $gray-light;
    }

    .icon {
      width: 1.25em;
      height: 1.25em;
      margin-top: 0.6em;

    }
  }

  &--contact {
    text-align: center;
    text-align: -webkit-center;
    width: 75px;


    &--active {
      color: $yes-button;
    }

    &--no {
      color: $gray-light;
    }

    .icon {
      width: 2em;
      height: 2em;
      margin-top: 0.6em;

    }
  }

  &--social-channels {
    width: 160px;
  }

  &--action-yes {
    text-align: center;
    text-align: -webkit-center;
    width: 60px;
  }

  &--action-no {
    text-align: center;
    text-align: -webkit-center;
    width: 60px;
  }
}

@include mq-down(md) {
  .list__cell__leadlist {
    &--country {
      width: 40px;
    }

    &--name {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--region {
      width: 200px;
      display: none;
    }

    &--website {
      width: 200px;
      display: none;
    }

    &--social-channels {
      width: 160px;
      display: none;
    }

    &--email {
      text-align: center;
      text-align: -webkit-center;
      width: 65px;
      display: none;


      &--active {
        color: $yes-button;
      }

      &--no {
        color: $gray-light;
      }

      .icon {
        width: 1.5em;
        height: 1.5em;
        margin-top: 0.6em;

      }
    }

    &--phone {
      text-align: center;
      text-align: -webkit-center;
      width: 50px;
      display: none;


      &--active {
        color: $yes-button;
      }

      &--no {
        color: $gray-light;
      }

      .icon {
        width: 1.25em;
        height: 1.25em;
        margin-top: 0.6em;

      }
    }

    &--contact {
      text-align: center;
      text-align: -webkit-center;
      width: 75px;


      &--active {
        color: $yes-button;
      }

      &--no {
        color: $gray-light;
      }

      .icon {
        width: 2em;
        height: 2em;
        margin-top: 0.6em;

      }
    }

    &--action-yes {
      text-align: center;
      text-align: -webkit-center;
      width: 60px;
    }

    &--action-no {
      text-align: center;
      text-align: -webkit-center;
      width: 60px;
    }
  }
}
.list__cell__qualifier {
  &--type {
    width: 40px;

    .icon {
      width: 1.25em;
      height: 1.25em;
      margin-top: 0.6em;
    }
  }

  &--name {
    width: 350px;
    padding-right: 1em;
    text-align:left;

    &--item {
      color: $gray-dark;
      overflow: hidden;
      text-overflow: ellipsis;

      @include hover-focus {
        color: darken($brand-primary, 15%);
        font-weight: 500;
      }
    }
  }

  &--username {
    width: 150px;
    padding-right: 1em;
    text-align: left;

    &--item {
      color: $gray-dark;
      overflow: hidden;
      text-overflow: ellipsis;

      @include hover-focus {
        color: darken($brand-primary, 15%);
        font-weight: 500;
      }
    }
  }
  &--person {
    width: 80px;
        text-align:left;

    .icon {
      width: 1.5em;
      height: 1.5em;
      margin-top: 0.6em;
    }
  }

  &--statistic {
    width: 35px;

    .icon {
      width: 1.5em;
      height: 1.5em;
      stroke: $gray-dark;
      margin-bottom: -4px;
    }
  }

  &--headLine {
    font-style: italic;
  }

  &--status {
    width: 60px;
  }

  &--action-edit {
    width: 150px;
  }

  &--action {
    width: 50px;
    text-align:left;
    }

    &--progress {
      width: 140px;
      text-align: left;
    }

  &--template-actions {
    width: 70px;
  }

  &--contacts-photo {
    width: 50px;
  }

}

@include mq-down(md) {
  .list__cell__leads {
    &--type {
      width: 40px;

      .icon {
        width: 1.25em;
        height: 1.25em;
        margin-top: 0.6em;
      }
    }

    &--name {
      width: unset;
      padding-right: 1em;
          text-align:left;

      &--item {
        color: $gray-dark;
        overflow: hidden;
        text-overflow: ellipsis;

        @include hover-focus {
          color: darken($brand-primary, 15%);
          font-weight: 500;
        }
      }
    }

    &--person {
      width: 80px;
      display: none;
    }

    &--statistic {
      width: 35px;
      display: none;
    }

    &--headLine {
      font-style: italic;
    }

    &--status {
      width: 60px;
      display: none;
    }

    &--action {
      width: 50px;
      display: none;
      }

      &--progress {
        width: 140px;
        text-align: left;
                display: none;
        }

    &--action-delete {
      width: 90px;
    }
  }
}
