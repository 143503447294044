// Form Component
.form {
  &__desc {
    margin-bottom: 1rem;
  }

  &__fields + &__fields {
    padding-top: 0.5rem;
    margin-top: 0.5rem;
    border-top: 1px solid $gray-lighter;
  }

  &__title {
    font-size: 0.875rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 12px;
    margin-bottom: $spacing-y;
    background-color: $gray-lighter;
    margin-top: 2rem;
  }

  &__actions {
    padding-top: 0.75rem;

    .box--narrow & {
      text-align: left;
    }

    @include mq(md) {
      text-align: right;
    }
  }

  &__actionsUpgrade {
    padding-top: 0rem;

    .box--narrow & {
      text-align: left;
    }

    @include mq(md) {
      text-align: right;
    }
  }

  &__actionsUpgradeButton {
    @include mq(md) {
      margin-left: 2rem !important;
    }
  }

  &__loadMoreButton {
    margin: auto;
    display: table;
    margin-top: 1rem;
    margin-bottom: 1rem;

    .icon {
      font-size: 2rem;
      margin: -0.6rem 0rem;
      transform: rotate(0);
    }

    // @include mq(md) {
    //   margin-left: 2rem !important;
    // }
  }
  &__li {
    margin-top: 1em;
    margin-left: 1.5em;
  }

  &__sm-text {
    font-size:0.75em;
    color: $gray-light;
  }
}
