// Lead Generation
.lead {
  border-bottom: 1px solid $gray-lighter;
  margin-bottom: $spacing-y;
  // padding-bottom: $spacing-y;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-top: -0.5rem;
  padding-top: 0.5rem;
  position: relative;
  top: -0.5rem;

  // @include hover {
  //   background-color: $gray-lightest;
  // }
  &__shortinfo {
    font-size: 0.75em;
  }

  &__inner {
    padding: 0 15px 0 15px;
  }

  &__decision {
    .btn {
      padding: 0.75em 0.5em 0.5625em;
    }
  }

  &__decision--no {
    height: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @include hover {
      color: #fff;
      background-color: $no-button;
    }

    .icon {
      height: 2em;
      width: 2.5em;
    }
  }

  &__decision--yes {
    height: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @include hover {
      color: #fff;
      background-color: $yes-button;
    }

    .icon {
      height: 2em;
      width: 2.5em;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    min-width: fit-content;
    flex: 1;
    padding-bottom: 0.5rem;
  }

  &__newssection {
    word-break: normal;
    line-height: 1.75em;

    @include mq-down(xl) {
      padding-top: 1.5rem;
    }
  }

  &__title {
    // display: flex;
    // flex: 1;
    font-size: 1rem;
    color: $brand-secondary;
    text-transform: capitalize;
  }

  &__address {
    display: flex;
    flex: 1;
    font-size: 1rem;
  }

  &__parameter {
    color: $gray-light;
  }

  &__list {
    list-style-type: none;
    margin: 0;
    word-break: break-all;

    li:nth-child(2n + 1) {
      background-color: $gray-lightest;
      // background-color: white;
    }

    &__box {
      padding: 1em;
      margin-top: -8px;
    }
  }

  &__newssource {
    font-size: 0.875rem;
  }

  &__newstitle {
    font-weight: 500;
  }

  &__newstext {
    overflow: hidden;
  }

  &__nonews {
    padding-top: 4em;
    padding-bottom: 3em;
  }

  &__popupnewsexplorertitle {
    width: 70%;
  }

  &__btn {
    display: flex;
    flex-wrap: wrap;
    align-content: baseline;

    .icon {
      height: 2em;
      width: 2em;
      margin-top: -0.5em;
      margin-bottom: -0.5em;
    }

    &--left {
      display: flex;
      flex-wrap: wrap;
      align-content: center;

      margin-top: 0.5rem;
      margin-right: 1em;

      .icon {
        transform: rotate(90deg);
        margin-left: -0.75em;
      }
    }

    &--right {
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      position: relative;
      margin-top: 0.5rem;

      .icon {
        transform: rotate(270deg);
        margin-right: -0.75em;
      }

      @include mq(lg) {
        justify-content: flex-end;
        right: 1rem;
        position: absolute;
      }
    }
  }

  &__table {
    width: 100%;
    border-collapse: collapse;
    border: none;
    vertical-align: top;
    line-height: 1.75em;

    @media screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
      margin-top: 1.5rem;
    }
  }

  &__td {
    width: 1%;
    padding-right: 0.5rem;
    white-space: nowrap;
  }

  &__filtertabs {
    padding: 2em;
  }

  &__tab-top {
    margin-top: -0.5em;
  }

  &__filtercategories {
    font-size: 1rem;
    text-align: right;
    padding-top: 1.4rem;
    padding-bottom: 0.25rem;

    .icon {
      width: 1em;
      height: 1em;
      color: $gray-dark;
      margin-left: 0.25em;
    }

    &__twidth {
      display: block;
      width: 20em;
      text-align: left;
    }
  }

  &__filtercategories_title {
    text-align: left;
    margin-top: -2px;
  }

  &__filteroptions {
    padding-top: 1rem;
    white-space: nowrap;
    display: block;
    padding-bottom: 0.5rem;
  }

  &__employees {
    width: 30%;
    margin-right: 1rem;
    display: inline-block;
  }

  &__turnover {
    width: 30%;
    margin-right: 1rem;
    display: inline-block;
  }

  &__industry {
    width: 100%;
    display: inline-block;
  }

  &__location {
    width: 48.75%;
    margin-right: 1rem;
    display: inline-block;
  }

  &__age {
    width: 30%;
    margin-right: 1rem;
    display: inline-block;
  }

  &__contactperson {
    width: 50%;
    position: relative;
    top: -0.35rem;
    display: inline-block;
    margin-bottom: -1rem;
  }

  &__contactperson:nth-child(1) {
    padding: 0px 5px 0px 0px;
  }

  &__contactperson:nth-child(2) {
    padding: 0px 0px 0px 10px;
  }

  &__behaviour {
    width: 70%;
    display: inline-block;
  }

  &__contextdetails {
    width: 49%;
    margin-right: 1rem;
    display: inline-block;
  }

  &__newscategory {
    width: 70%;
    margin-right: 1rem;
    display: inline-block;
  }

  &__social {
    display: inline-block;
    width: 22px;
    height: 22px;
    line-height: 14px;
    text-decoration: none;
    color: #fff;
    border-radius: 50%;
    padding: 5px;
    margin-left: 0.2rem;
    margin-top: 0rem;
    text-align: center;
    overflow: hidden;
    vertical-align: middle;
    background-color: $gray-light;

    .icon {
      width: 12px;
      height: 12px;
    }

    &--linkedin {
      background-color: $linkedin-color;
    }

    &--xing {
      background-color: $xing-color;
    }

    &--facebook {
      background-color: $facebook-color;
    }

    &--youtube {
      background-color: $youtube-color;
    }

    &--twitter {
      background-color: $twitter-color;
    }

    &--instagram {
      // background-color: #C13584;
      background: #d6249f;
      background: radial-gradient(circle at 30% 107%,
          #fdf497 0%,
          #fdf497 5%,
          #fd5949 45%,
          #d6249f 60%,
          #285aeb 90%);
    }
  }

  &__youtube {
    display: inline-block;
    margin-left: 2px;
    text-decoration: none;
    color: $gray-light;

    &--active {
      color: $youtube-color;
    }

    .icon {
      margin-bottom: -5px !important;
    }
  }

  //   &__slidewrapper{
  //   background-color: blue;
  //   transition: background-color 1s linear;
  //   -webkit-transition: background-color 1s linear;
  //   -moz-transition: background-color 1s linear;
  // @include hover {
  //   background-color: $gray-lightest;
  //   left: 0;
  // }
  // }

  // &__slidewrapper:hover #slide {
  //   transition: 1s;
  //   left: 0;
  // }

  @include mq(md) {
    &__stats {

      // make boxes equal height
      .box,
      [class^="col"] {
        display: flex;
        flex-direction: column;
      }

      .box,
      .box__body {
        flex: 1 0 auto;
        padding: 0.5rem 0.5rem 0.25rem 0.5rem;
      }
    }
  }
}

.leadListDetail {
  &__criteria {
    margin-left: 2rem;
  }

  &__criteriaSecond {
    margin-left: 1rem;
    font-size: 0.85rem;
  }
}

.statisticsGraph>div>div>div.recharts-wrapper>div.recharts-legend-wrapper>ul {
  font-size: calc(10px + 0.095vw);
}

.statisticsIndustries {
  margin-top: 1%;
}

.potential_companies_number {
  display: inline-block;
  padding: 5px 20px;
  font-size: 25px;
}

.potential_companies_loading {
  margin-top: -30px;
}

.industriesTitle {
  text-transform: uppercase;
}

.leadEmployees {
  &__list {
    padding-top: 2em;
    list-style: none;
    display: flex;
    overflow-x: auto;
    margin-left: -15px;
    margin-right: -15px;
  }

  &__employee {
    text-align: center;
    font-size: 0.875rem;
    width: 22em;
  }

  &__employees {
    &__item {
      background-color: transparent !important; //hack for Lead site to overwrite background color
      padding-bottom: 1em;
    }
  }
}

.__react_component_tooltip.place-top.type-dark {
  max-width: 35%;
}

.lead__button {
  &__info {
    display: flex;
    flex-wrap: wrap;
  }

  &__gobackbuttonsalesforce {
    margin-left: auto;
    margin-bottom: 10px;
  }
}

.divleadpartialupdate {
  margin-bottom: 10px;

}

.divleadpartialupdate>span {
  margin-right: 6px;
}

.checkboxleadpartialupdate {
  margin-right: 5px
}

.personcheckboximport {
  transform: scale(1.5);
  cursor: pointer;
}

.rowpersonimport {
  margin-left: 8px;
}