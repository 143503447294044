.process_qualifier {
  &__wrapper {
    margin: 0 auto;
    padding: 20px 0;
    font-size: 13px;
    line-height: 1.5;
    letter-spacing: 0.025em;
    color: $gray-darkest;
    font-weight: 400;
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__services-wrapper {
    padding: 0 !important;
    background-image: none;
    height: 100%;

    h3 {
      text-align: center;
      font-size: 28px;
      margin: 0 0 40px 0 !important;
      font-weight: 500;
    }

    .service {
      // .marketing.service
      height: auto;
      width: 100%;
      border-left: 4px solid #9da7b0;
      height: 100%;
      margin-top: 12px;
    }
  }

  &__floating-blurb {
    text-align: left !important;
    // margin: 2% 0;
    padding: 0px 0 25px 15px !important;
    // width: 90% !important;
    right: auto !important;
    top: auto !important;

    h4 {
      padding: 0px 4px 0;
      // line-height: 20px;
      // font-size: 12px;
      // color: #212121;
      // text-transform: capitalize;
      // font-weight: 700;
      // letter-spacing: 0.025em;
      margin: 0 !important;
      text-align: left;
      // width: 75%;
    }

    p {
      padding: 5px 4px 0;
      line-height: 30px;
      font-weight: 400;
      color: #555;
      font-size: 20px;
    }

    p.capital {
      font-size: 16px !important;
      padding-top: 23px !important;
    }
  }
&__progressbar {
  width: 90%;
  background-color: $brand-blue-lightest;
  border-radius: 100px;
  padding: 0px;

  &:hover {
    .process_qualifier__tooltip {
      visibility: visible;
    }
  }

  &__inner {
    height: 15px;
    border-radius: 100px;
    background-color: $brand-blue;
  }
}


&__progressbar_success {
  background-color: $brand-blue-lightest;

  .process_qualifier__progressbar__inner {
    background-color: $yes-button;
  }
}

&__tooltip {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

}

.counter {
  display: table-cell;
  margin: 1.5%;
  font-size: 30px;
  background-color: $brand-blue;
  width: 50px;
  border-radius: 50%;
  color: white;
  height: 50px;
  text-align: center;
  vertical-align: middle;
}

.counter-invite {
  background-color: $brand-blue;
}

.counter-replytiming {
  background-color: #FFBB28;
}

.counter-action {
  background-color: $brand-primary;
}

.counter-reply {
  background-color: $brand-cyan;
}



// helpers

$toggler-size: 40px;
$toggler-color: $brand-blue;
$toggler-transition: transform 0.5s,
top 0.5s;

$item-count: 6;
$item-size: $toggler-size * 2;
$item-color: $brand-blue;
$item-transition: 0.5s;

@mixin size($width, $height: $width) {
  width: ($width/100) * 70;
  height: ($height/100) * 70;
}

@mixin absolute-center {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

@mixin line {
  @include size($toggler-size, $toggler-size/8);
  display: block;
  z-index: 1;
  border-radius: $toggler-size/16;
  background: rgba($toggler-color, 0.7);
  transition: $toggler-transition;
}
.menu {
  text-align: center;
  z-index: 0 !important;
}

.menu-container {
  margin: 50px 0px 10px 0px;
}

.menu-toggler {
  @include absolute-center;
  @include size($toggler-size);
  z-index: 2;
  opacity: 0;
  cursor: pointer;

  &:hover+label,
  &:hover+label:before,
  &:hover+label:after {
    background: white;
  }
}

.menu-toggler:checked {
  &+label {
    background: transparent;
  }

  &+label:before,
  &+label:after {
    top: 0;
    width: 30px;
    transform-origin: 50% 50%;
  }

  &+label:before {
    transform: rotate(45deg);
  }

  &+label:after {
    transform: rotate(-45deg);
  }
}

.menu-toggler:checked~ul {
  .menu-item {
    opacity: 1;

    a {
      svg {
        margin-bottom: 7px !important;
      }
    }
  }

  @for $i from 0 through $item-count - 1 {
    .menu-item:nth-child(#{$i + 1}) {
      // @if $i==2 {
      //     transform: rotate(530deg / $item-count * $i) translateX(-$item-size + 40px);
      // }

      @if $i==1 {
        transform: rotate(550deg / $item-count * $i) translateX(-$item-size + 10px);
      }

      @else if $i==2 {
        transform: rotate(540deg / $item-count * $i) translateX(-$item-size + 10px);
      }

      @else {
        transform: rotate(540deg / $item-count * $i) translateX(-$item-size + 10px);
      }
    }
  }

  .menu-item a {
    pointer-events: auto;
  }
}

.menu-toggler+label {
  @include line;
  @include absolute-center;
  background: none !important;

  &:before,
  &:after {
    @include line;
    content: "";
    position: absolute;
    left: 0;
    background: white;
  }

  // &:before {
  //     top: $toggler-size/4;
  //     transform: rotate(90deg);
  // }

  &:after {
    top: 0;
    transform: rotate(-90deg);
  }
}

@for $i from 0 through $item-count - 1 {
  .menu-item:nth-child(#{$i + 1}) a {
    @if $i==1 {
      transform: rotate(-550deg / $item-count * $i);
    }

    @else {
      transform: rotate(-540deg / $item-count * $i);
    }
  }
}

.menu-item {
  @include absolute-center;
  @include size($item-size);
  display: block;
  opacity: 0;
  transition: $item-transition;
}

.menu-item a {
  display: block;
  width: inherit;
  height: inherit;
  line-height: $item-size;
  color: rgba($item-color, 0.7);
  background: rgba($brand-blue, 0.2);
  border-radius: 50%;
  text-align: center;
  text-decoration: none;
  font-size: $item-size/2;
  pointer-events: none;
  transition: 0.2s;

  &:hover {
    box-shadow: 0 0 0 $item-size/40 rgba($brand-blue, 0.3);
    color: $item-color;
    background: rgba($brand-blue, 0.3);
    font-size: $item-size/1.8;
  }
}

span.circle {
  margin: 1.5%;
  font-size: 30px;
  background-color: $brand-blue;
  width: 50px;
  border-radius: 50%;
  color: white;
  height: 50px;
  text-align: center;
  vertical-align: middle;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  display: inline-block;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
}

.border {
  height: 30px;
  margin-left: 17px;
  padding: 10px;
  animation: border-dance 5s infinite linear;
  animation-direction: reverse;
}

.border-invite {
  background: linear-gradient(90deg, $brand-blue 0%, transparent 0%),
    linear-gradient(90deg, $brand-blue 0%, transparent 0%),
    linear-gradient(0deg, $brand-blue 50%, transparent 50%),
    linear-gradient(0deg, $brand-blue 0%, transparent 0%);
  background-repeat: repeat-x,
    repeat-x,
    repeat-y,
    repeat-y;
  background-size: 15px 4px,
    15px 4px,
    4px 15px,
    4px 15px;
  background-position: 0px 0px,
    200px 100px,
    0px 100px,
    200px 0px;

}

.border-replytiming {
  background: linear-gradient(90deg, $brand-blue 0%, transparent 0%),
    linear-gradient(90deg, $brand-blue 0%, transparent 0%),
    linear-gradient(0deg, #FFBB28 50%, transparent 50%),
    linear-gradient(0deg, $brand-blue 0%, transparent 0%);
  background-repeat: repeat-x,
    repeat-x,
    repeat-y,
    repeat-y;
  background-size: 15px 4px,
    15px 4px,
    4px 15px,
    4px 15px;
  background-position: 0px 0px,
    200px 100px,
    0px 100px,
    200px 0px;

}

.border-action {
  background: linear-gradient(90deg, $brand-blue 0%, transparent 0%),
    linear-gradient(90deg, $brand-blue 0%, transparent 0%),
    linear-gradient(0deg, $brand-primary 50%, transparent 50%),
    linear-gradient(0deg, $brand-blue 0%, transparent 0%);
  background-repeat: repeat-x,
    repeat-x,
    repeat-y,
    repeat-y;
  background-size: 15px 4px,
    15px 4px,
    4px 15px,
    4px 15px;
  background-position: 0px 0px,
    200px 100px,
    0px 100px,
    200px 0px;

}

.border-reply {
  background: linear-gradient(90deg, $brand-blue 0%, transparent 0%),
    linear-gradient(90deg, $brand-blue 0%, transparent 0%),
    linear-gradient(0deg, $brand-cyan 50%, transparent 50%),
    linear-gradient(0deg, $brand-blue 0%, transparent 0%);
  background-repeat: repeat-x,
    repeat-x,
    repeat-y,
    repeat-y;
  background-size: 15px 4px,
    15px 4px,
    4px 15px,
    4px 15px;
  background-position: 0px 0px,
    200px 100px,
    0px 100px,
    200px 0px;
}

@keyframes border-dance {
  0% {
    background-position: 0px 0px, 300px 116px, 0px 150px, 216px 0px;
  }

  100% {
    background-position: 300px 0px, 0px 116px, 0px 0px, 216px 150px;
  }
}

.rowcentertext {
  text-align: center;
  display: flex;
  justify-content: center;
}

#main>section>div>div:nth-child(3)>div>div>div>div>div>div>div>div>div:nth-last-child(2)>div:last-child {
  display: none;
}

.close-step {
  display: none;
  position: absolute;
  right: 5px;
  top: 7px;
  width: 32px;
  height: 32px;
  opacity: 0.6;
  z-index: 10;
}

.close-step:hover {
  opacity: 1;
}

.close-step:before,
.close-step:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 20px;
  width: 3px;
  background-color:$gray-dark;
}

.close-step:before {
  transform: rotate(45deg);
}

.close-step:after {
  transform: rotate(-45deg);
}

#main>section>div>div:nth-child(3)>div>div>div>div>div>div>div>div>div:nth-last-child(2)>div:nth-last-child(2)>div>div>div>div>div>a {
  display: block;
}

.box-invite {
  .box {
    .box__body {
      border: 2px solid $brand-blue;
    }
  }

}

.box-replytiming {
  .box {
    .box__body {
      border: 2px solid #FFBB28;
    }
  }

}

.box-action {
  .box {
    .box__body {
      border: 2px solid $brand-primary;
    }
  }

}

.box-reply {
  .box {
    .box__body {
      border: 2px solid $brand-cyan;
    }
  }

}

@include mq-down(lg) {
  .MuiGrid-grid-xs-6 {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}

.actionlist {
    display: flex;
    justify-content: flex-start;
  &__status {

    &--play {
      color: $gray-lighter;
      font-size: 65px;

      &--active {
        color: $brand-cyan;
      }

      &:hover {
        color: $brand-cyan;
      }
    }

    &--pause {
      color: $gray-lighter;
      font-size: 38px;
      margin-top: 20px;

      &--active {
        color: #FFBB28;
      }

      &:hover {
        color: #FFBB28;
      }
    }
    &--save {
      stroke: $gray-lighter;
      color: $gray-lighter;
      font-size: 53px;
      margin-top: 10px;
      margin-left: 5px;

      &--active {
        stroke: $brand-blue;
        color: $brand-blue;
      }
      &:hover {
        stroke: $brand-blue;
        color: $brand-blue;
      }
    }
  }
  &__buttoncontainer {
    margin-top: -20px;
    min-width: 150px;
  }
  }

@include mq-down(lg) {
  .containeractionlist {
    margin-bottom: 15px !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}

//progress bar

.outer {
  //connected to the margin-top progressbarcontainer > div
  margin-bottom: 25px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: 20px;
  width: 100%;
  border: 5px solid $gray-lighter;
  border-radius: 50px;
  background: #F7F7F7;
}

.inner {
  height: 12px;
  width: 250px;
  background: $brand-blue;
  border-radius: 25px;
  -webkit-transition: width 100ms ease-out;
  transition: width 100ms ease-out;

  &--play {
    background: $brand-cyan;
  }

  &--pause {
    background: #FFBB28;
  }

  &--stop {
    background: $brand-primary;
  }
}

.progressbarcontainer {
  margin-top: -20px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;

  &__percentage {
    margin-top: 25px;
    font-size: 30px;
  }
}

@include mq-down(lg) {
  .progressbarcontainer {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}

//progress bar

.navlimitsandschedule {
  border: 0px;

  div {
    h4 {
      background: white !important;
      padding-left: 0px;
    }

  }
}

@media only screen and (min-width: 1200px) {
  .responsive-table thead {
    visibility: visible;
  }
}

@media (min-width: 1024px) {
  .navlimitsandschedule {

    div {

      .nav__campaigns__content.is-expanded {
        max-height: 600px !important;
        background: white !important;
      }
    }
  }
}
.qualifier-login {
  padding-top: 1em;

  &__linkedn {
    padding-top: 0.5em;
  }

  &__connector {
    display: flex;
    text-align: left;

    .icon {
      color: $linkedin-color;
      width: 3em;
      height: 3em;
    }
  }

  &__token {
    display: flex;
    margin-top: -14px;
  }

  &__input {
    padding-top: 0.75em;
  }

  &__button {
    padding-top: 0px;
  }

  &__instruction {
    text-align: left;
    font-size: 0.75em;
    padding-top: 0.5em;
    margin-bottom: -2.5em;

    .icon {
      width: 1.5em;
      height: 1.5em;
      margin-right: 1em;
      cursor: auto;
    }
  }
  &__savebutton {
    margin-left: 2em;
    margin-top: 14px;
  }
}

@include mq(lg) {
  .qualifier-login__button {
    button {
      min-width: 350px;
    }
  }
}

.black_list_container {
  .box__content {
    text-align: center;
  }
}
.span-limits {
  margin-left: 8px;
  font-size: 14px;
  font-weight: normal;
}

.h5-limits {
  margin-top: 17px
}