// Box Component for News
.box_news {
  background-color: $white;
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;

  @include mq-down(md) {
    padding: 0.5rem 0 0 0.5rem;
  }

  &--narrow {
    max-width: 21rem;
    margin: $spacing-y auto;
  }

  &__title {
    font-weight: 400;
    margin-bottom: 0.5rem;
    white-space: nowrap;

    em {
      font-style: normal;
      color: $brand-primary;
    }
  }

  &__body {
    position: relative;
    background-color: #fff;
    min-height: 4rem;
    border: 1px solid $gray-lighter;
    border-radius: 0.25rem;
  }

  &__togglebtn {
    display: none;
    border: 0;
    background: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    width: 100%;
    padding: $box-padding;
    padding-right: $box-toggle-arrow-size; //space for icon
    font-size: 1.125rem;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;

    .icon {
      display: block;
      width: $box-toggle-arrow-size;
      height: $box-toggle-arrow-size;
      color: $gray-dark;
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -28px;
      transform: rotate(0);
    }
  }

  &__stats {
    // make boxes equal height
    .box_news,
    [class^="col"] {
      display: flex;
      flex-direction: column;
    }

    .box_news,
    .box_news__body {
      flex: 1 0 auto;
      padding: 0.5rem;
    }
  }
}

.box_news--collapsible {
  border: 1px solid $gray-lighter;
  border-radius: 0.25rem;
  background-color: $white;

  @include mq-down(md) {
    .box_news__togglebtn {
      display: block;
    }

    .box_news__content {
      width: 100%;
      height: 1px;
      overflow: hidden;
      visibility: hidden;
    }

    &.box_news--expanded {
      .box_news__togglebtn {
        height: $box-toggle-arrow-size;
        width: $box-toggle-arrow-size;
        padding: 0;
        bottom: auto;
        left: auto;
        text-indent: 100%;

        .icon {
          transform: rotate(-180deg);
        }
      }

      .box_news__content {
        height: auto;
        visibility: visible;
      }
    }
  }
}

@media (hover: hover) {
@include mq(md) {
  .box_events {
    .box_news__body {
      div.events__stats {
        section.events__stats {
          overflow-y: auto;
          height: 639px;
        }
        section.events__stats--full_length {
          overflow-y: auto;
          height: 743px;
        }
        .events__button__container {
          .events__loading {
            padding-top: 1rem;
          }
          display: flex;           
          flex-direction: column;  
          justify-content: center; 
          align-items: center;     
          min-height: 104px
        }
      }
    }
  }
}}