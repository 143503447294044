// Searchfieldtrigger Component
// components/Searchfieldtrigger.jsx
.searchfieldtrigger {
  position: relative;
  top: -3.5rem;
  left: 0rem;
  z-index: z('search');
  height: 2rem;
  background-color: #FFF;
  color: $brand-secondary;
  box-shadow: $box-shadow-elevated;
  border: 1px solid $brand-secondary;
  border-radius: 0.25rem;
  .container {
    align-items: center;
    height: 100%;
  }
  &__label {
    display: none;
    width: 2rem;
    height: 2rem;
    font-size: 1.75rem;
    position: absolute;
    left: .5rem;
    top: 50%;
    margin-top: -1rem;
  }
  &__input {
    display: block;
    width: 100%;
    padding: .2rem 0;
    border: 0;
    border-bottom: 1px solid $brand-secondary;
    font-size: 1rem;
    font-weight: 400;
    color: $brand-secondary;
  }
  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: $brand-secondary;
  }
  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: $brand-secondary;
    opacity: 1;
  }
  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $brand-secondary;
    opacity: 1;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $brand-secondary;
  }
  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $brand-secondary;
  }
  @include mq(md) {
    display: block;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    height: auto;
    max-width: 20rem;
    background-color: transparent;
    box-shadow: none;
    .container {
      display: block;
      height: auto;
      padding: 1px;
    }
    &__label {
      display: block;
    }
    &__input {
      padding: .25rem .25rem .25rem 3.25rem;
      border-radius: .5rem;
      border: 0;
    }
    &__close {
      display: none;
    }
  }
  @include mq(lg) {
    max-width: 45rem;
  }
}