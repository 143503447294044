.tab-list {
  border-bottom: 1px solid $gray-lighter;
  padding-left: 0;
  margin-bottom: 0rem;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 1.25rem;
  color: $gray;
  font-weight: 600;

  &:hover {
    color: $gray-dark;
    cursor: pointer;
  }
  @include mq-down(xl) {
    margin-right: -1px;
    margin-bottom: -1px;
    border: 1px solid $gray-lighter;
  }
}

.tab-list-active {
  background-color: $gray-lightest;
  color: $brand-secondary;
  border: solid $gray-lighter;
  border-width: 1px 1px 0 1px;
  border-top: 2px solid $brand-secondary;

  &:hover {
    color: $brand-secondary;
    cursor: text;
  }
@include mq-down(xl) {
  margin-top: -1px;
}
}

.tab-list-locked {

  &:hover {
    cursor: default;
  }

}



.tab-content {
  background-color: $gray-lightest;
  padding-top: 0.5rem;
  border-left: 1px solid $gray-lighter;
  border-bottom: 1px solid $gray-lighter;
  border-right: 1px solid $gray-lighter;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.tabs {
  padding: 1.5em 0.5em 1em 0.5em;
}

.tab {
  &__nopermission {
    color: #e2e2e2;
    font-size: 120px;
    line-height: 100%;
    position: relative;
    top: 50%;
  }

  &__header {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  &__center {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &__btn {
    width: 100%;
    cursor: pointer;
  }

  &__logo {
    padding-left: 0.4rem;
    padding-top: 0.2rem;
    padding-right: 5rem;
  }

  &__description {
    font-size: 0.85rem;

    @include mq(md) {
      margin-left: -5rem;
    }
  }

  &__company {
    margin-top: -1.02em;
    margin-right: -0.04em;
    margin-bottom: -0.3em;
    margin-left: -0.05em;
  }


}