// Component to show if user has not the necessary permission to see this site
.allowance {
  position: fixed;
  top: 40%;
  left: 33.3%;
  width: 40rem;
  height: 40rem;

  &__update {
    position: absolute;
    width: 100%;
    height: 50%;
    top: 40%;
    text-align: center;

    .icon {
      height: 4em;
      width: 4em;
      color: $brand-blue;
    }
  }
}
