//Social Sharing Links
.channels {
  // margin-top: 1rem;
  &__youtube {
    display: inline-block;

    margin-right: 5px;
    margin-left: -2px;
    text-decoration: none;
    color: $gray-light;

    @include hover-focus {
      transform: scale(1.1);
    }

    &--active {
      color: $youtube-color;

      @include hover-focus {
        color: darken($youtube-color, 10%);
      }
    }
  }

  &__link {
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 22px;
    text-decoration: none;
    color: #fff;
    border-radius: 50%;
    // padding: -2px;
    margin-right: 0.4rem;
    // margin-top: .3rem;
    transition: background-color 0.2s linear,
      transform 0.2s $animation-curve-default;
    text-align: center;
    transform: scale(1);
    overflow: hidden;
    vertical-align: middle;
    margin-top: -6px;

    @include hover-focus {
      transform: scale(1.1);
    }

    .icon {
      width: 12px;
      height: 12px;
    }

    &--linkedin {
      background-color: $gray-light;

      &--active {
        background-color: $linkedin-color;

        @include hover-focus {
          background-color: darken($linkedin-color, 10%);
        }
      }
    }

    &--xing {
      background-color: $gray-light;

      &--active {
        background-color: $xing-color;

        @include hover-focus {
          background-color: darken($xing-color, 10%);
        }
      }
    }

    &--facebook {
      background-color: $gray-light;

      &--active {
        background-color: $facebook-color;

        @include hover-focus {
          background-color: darken($facebook-color, 10%);
        }
      }
    }

    &--twitter {
      background-color: $gray-light;

      &--active {
        background-color: $twitter-color;

        @include hover-focus {
          background-color: darken($twitter-color, 10%);
        }
      }
    }

    &--youtube {
      background-color: $gray-light;

      &--active {
        background-color: $youtube-color;

        @include hover-focus {
          background-color: darken($youtube-color, 10%);
        }
      }
    }
  }

  &__link_big {
    width: 40px;
    height: 40px;
    line-height: 53px;

    .icon {
      width: 24px;
      height: 24px;
    }
  }

  &__link_big_youtube {
    width: 40px;
    height: 40px;
    line-height: 53px;

    .icon {
      width: 40px;
      height: 40px;
    }
  }

  &__link_big_blue {
    @include hover-focus {
      transform: scale(1.1);
    }

    width: 40px;
    height: 40px;
    line-height: 53px;

    .icon {
      width: 40px;
      height: 40px;
    }

    color: $brand-blue;
    margin-left: -7px;
  }
}
