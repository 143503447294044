// Toast Messages

.toast {
  position: fixed;
  bottom: 0;
  left: 0;
  right:0;
  width:100%;
  padding: 1rem 2rem;
  border-width: 1px;
  border-style: solid;
  border-bottom:0;
  border-radius:6px 6px 0 0;

  z-index: z('content-overlay');
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;

  will-change: transform;
  transform: translate(0, 80px);
  transition: transform 0.25s $animation-curve-fast-out-linear-in;
  pointer-events: none;

  &__message {
    vertical-align: middle;
    float: left;
  }

  &__action {
    float: right;
    padding:.5rem 1rem;
    margin-left:1rem;
    transition:background-color .2s linear;

    &::-moz-focus-inner {
      border: 0;
    }
    &:not([aria-hidden]) {
      opacity: 1;
      pointer-events: auto;
    }
  }

  @include mq(md) {
    min-height: 48px;
    max-height: 80px;
  }

  // Show Toast
  &--active {
    transform: translate(0, 0);
    pointer-events: auto;
    transition: transform 0.25s $animation-curve-linear-out-slow-in;
  }

  // Type Classes
  // related to django message tags
  &--info {
    @extend .alert--info;
  }
  &--warning {
    @extend .alert--warning;
  }
  &--error {
    @extend .alert--error;
  }
  &--success {
    @extend .alert--success;
  }
}