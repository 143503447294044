// Custom Checkbox Field

$checkbox-indicator-size: 22px;
$checkbox-indicator-size-small: 18px;

.checkbox {
  @extend %field;
  @include field-states;

  padding:.75rem 0;

  &__label {
    position:relative;
    cursor:pointer;
    padding-left:2rem;
    margin:0;
    min-height:$checkbox-indicator-size;
    color:$body-color;
  }

  &__input {
    @include sr-only;
  }

  &__feedback {
    @extend %field__feedback;
    padding-left:2rem;
  }

  &__indicator {
    display:inline-block;
    width: $checkbox-indicator-size;
    height: $checkbox-indicator-size;
    position:absolute;
    top:-1px;
    left:0;
    background-color:#FFF;
    border:1px solid $gray-light;

    &::after {
      content: '';
      display:none;
      position:absolute;
      top:3px;
      left:3px;
      right:3px;
      bottom:3px;
      background: transparent url('/static/svg/check.svg') no-repeat center center;
      background-size:contain;
    }

    &.checkbox__small {
      top: 14px;
      width: $checkbox-indicator-size-small;
      height: $checkbox-indicator-size-small;

      & + .checkbox__caption {
        color: $gray;
        font-size: 11px;
      }
    }

    //checked state
    .checkbox__input:checked + &,
    .checkbox--checked + & {
      border:2px solid $gray-dark;

      &::after {
        display:block;
      }
    }

  }
}