//
// Floats
//

.clearfix {
  @include clearfix;
}

//
// Screenreaders
//

.sr-only {
  @include sr-only();
}

.sr-only-focusable {
  @include sr-only-focusable();
}

//Flexbox Utility
.flex-center {
  display:flex;
  justify-content: center;
  align-items: center;
}

.fill-out {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
