.progress {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 0.75rem;

  &__bar {
    width: 100%;
    background-color: $brand-blue-lightest;
    border-radius: 100px;
    padding: 0px;
    margin-bottom: 1rem;

    &__inner {
      height: 15px;
      border-radius: 100px;
      background-color: $brand-blue;
    }
  }

  &__count {
    font-size: 1.875rem; //30px
    font-weight: 200;
    margin-top: -0.75rem;

    &__slash {
      padding: 0 .25rem;
    }
  }

  &__divider,
  &__text {
    display: none;
  }

  &__danger {
    background-color: $brand-coral;

    .progress__bar__inner {
      background-color: $state-error;
    }
  }

  @include mq(md) {
    &__bar {
      flex: 1 0 60%;
      max-width: 60%;
      margin: .25rem 0 0 0;
    }

    &__count {
      text-align: right;
      flex: 1 0 40%;
      max-width: 40%;
      padding-left: 1rem;
    }

    &__text {
      display: block;
      flex: 1;
      padding-right: 2rem;
    }

    &__divider {
      display: block;
      width: 100%;
      margin: 1rem 0;
    }
  }
}