// Open Position List Component

.positions {
  &__list {
    margin: 0;
  }

  &__item {
    display: flex;
    margin: 0 0 0.8rem 5px;
  }
}

.position {
  &__created {
    flex: none;
    width: 150px;

    line-height: 1.5rem;
    font-size: 0.9rem;
    font-weight: 300;
  }

  &__title {
    flex: 1;

    color: $gray-dark;
    line-height: 1.5rem;
    font-weight: 400;
  }
}
